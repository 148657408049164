import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/wolfsbergSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
  text-transform: uppercase;
`;

const Question = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 24px;
  line-height: 1.5;
`;

const SubSection = styled.div`
  margin-bottom: 32px;
`;

const CategoryTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 24px;
`;

const RadioLabel = styled.label`
 display: flex;
  justify-content: center;  // Center the radio button
  padding-left: 20px;
  font-size: 14px;
  cursor: pointer;
`;

const PoliciesTable = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px 60px;  // Fixed width columns for Yes/No
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
`;

const HeaderItem = styled.div`
  font-weight: 500;
  text-align: ${props => props.align || 'left'};
  // Align text with radio buttons
  &:nth-child(2) { padding-left: 20px; }  // Adjust for Yes column
  &:nth-child(3) { padding-left: 20px; }  // Adjust for No column
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px 60px;  // Match header columns
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
`;

const PolicyText = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

const Input = styled.input`
  width: 60%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }

  &::placeholder {
    color: #999;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const AMLPoliciesForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('amlpolicies')) || {};

    const basicPolicies = [
        'Money laundering',
        'Terrorist financing',
        'Sanctions violations'
    ];

    const proceduresList = [
        'Prohibit the opening and keeping of anonymous and fictitious named accounts',
        'Prohibit the opening and keeping of accounts for unlicensed banks and/or NBFIs',
        'Prohibit dealing with other entities that provide banking services to unlicensed banks',
        'Prohibit accounts/relationships with shell banks',
        'Prohibit dealing with another entity that provides services to shell banks',
        'Prohibit opening and keeping of accounts for Section 311 designated entities',
        'Prohibit opening and keeping of accounts for any of unlicensed/unregulated remittance agents, exchanges houses, casa de cambio, bureaux de change or money transfer agents',
        'Assess the risks of relationships with domestic and foreign PEPs, including their family and close associates',
        'Assess the risks of relationships with domestic and foreign PEPs, including their family and close associates',
        'Define the process for escalating financial crime risk issues/potentially suspicious activity identified by employees',
        'Define the process for escalating financial crime risk issues/potentially suspicious activity identified by employees',
        'Outline the processes regarding screening for sanctions, PEPs and Adverse Media/Negative News'
    ];

    const handleChange = (key, value) => {
        dispatch(updateStepData({
            step: 'amlpolicies',
            data: { [key]: value }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>AML, CTF AND SANCTIONS POLICIES AND PROCEDURES</SectionTitle>

                <Question>
                    Has the Entity documented policies and procedures consistent with applicable AML, CTF & Sanctions regulations and requirements
                    to reasonably prevent, detect and report:
                </Question>

                {basicPolicies.map((policy) => (
                    <SubSection key={policy}>
                        <CategoryTitle>{policy}</CategoryTitle>
                        <RadioGroup>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    name={`policy_${policy}`}
                                    value="no"
                                    checked={formData[policy] === 'no'}
                                    onChange={() => handleChange(policy, 'no')}
                                />
                                No
                            </RadioLabel>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    name={`policy_${policy}`}
                                    value="yes"
                                    checked={formData[policy] === 'yes'}
                                    onChange={() => handleChange(policy, 'yes')}
                                />
                                Yes
                            </RadioLabel>
                        </RadioGroup>
                    </SubSection>
                ))}

                <Question>Does the Entity have policies and procedures that:</Question>

                <PoliciesTable>
                    <TableHeader>
                        <HeaderItem>Products and services</HeaderItem>
                        <HeaderItem align="center">Yes</HeaderItem>
                        <HeaderItem align="center">No</HeaderItem>
                    </TableHeader>

                    {proceduresList.map((procedure, index) => (
                        <TableRow key={index}>
                            <PolicyText>{procedure}</PolicyText>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    name={`procedure_${index}`}
                                    value="yes"
                                    checked={formData[`procedure_${index}`] === 'yes'}
                                    onChange={() => handleChange(`procedure_${index}`, 'yes')}
                                />
                            </RadioLabel>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    name={`procedure_${index}`}
                                    value="no"
                                    checked={formData[`procedure_${index}`] === 'no'}
                                    onChange={() => handleChange(`procedure_${index}`, 'no')}
                                />
                            </RadioLabel>
                        </TableRow>
                    ))}
                </PoliciesTable>

                <Question>
                    Has the Entity defined a risk tolerance statement or similar document which defines a risk boundary around their business?
                </Question>
                <RadioGroup>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasRiskTolerance"
                            value="no"
                            checked={formData.hasRiskTolerance === 'no'}
                            onChange={() => handleChange('hasRiskTolerance', 'no')}
                        />
                        No
                    </RadioLabel>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasRiskTolerance"
                            value="yes"
                            checked={formData.hasRiskTolerance === 'yes'}
                            onChange={() => handleChange('hasRiskTolerance', 'yes')}
                        />
                        Yes
                    </RadioLabel>
                </RadioGroup>

                <Question>
                    Does the Entity have record retention procedures that comply with applicable laws?
                </Question>
                <RadioGroup>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasRetentionProcedures"
                            value="no"
                            checked={formData.hasRetentionProcedures === 'no'}
                            onChange={() => handleChange('hasRetentionProcedures', 'no')}
                        />
                        No
                    </RadioLabel>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasRetentionProcedures"
                            value="yes"
                            checked={formData.hasRetentionProcedures === 'yes'}
                            onChange={() => handleChange('hasRetentionProcedures', 'yes')}
                        />
                        Yes
                    </RadioLabel>
                </RadioGroup>

                {formData.hasRetentionProcedures === 'yes' && (
                    <SubSection>
                        <Label>Retention period</Label>
                        <Input
                            placeholder="Placeholder text"
                            value={formData.retentionPeriod || ''}
                            onChange={(e) => handleChange('retentionPeriod', e.target.value)}
                        />
                    </SubSection>
                )}
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default AMLPoliciesForm;