import { createSlice } from '@reduxjs/toolkit';

const destinationSlice = createSlice({
    name: 'destinations',
    initialState: {
        data: [],
        count: 0,
        isLoading: false,
        error: null,
        originatingCountry: '',
        gridData: [],
    },
    reducers: {
        setDestinations: (state, action) => {
            state.data = action.payload;
            state.count = action.payload.length;
        },
        setOriginatingCountry: (state, action) => {
            state.originatingCountry = action.payload;
        },
        setGridData: (state, action) => {
            state.gridData = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        }
    }
});

// Selectors for cleaner state access
export const selectDestinations = (state) => state.destinations.data;
export const selectIsLoading = (state) => state.destinations.isLoading;
export const selectError = (state) => state.destinations.error;
export const selectCount = (state) => state.destinations.count;
export const selectOriginatingCountry = (state) => state.destinations.originatingCountry;

export const {
    setDestinations,
    setLoading,
    setOriginatingCountry,
    setGridData,
    setError,
    clearError
} = destinationSlice.actions;

export default destinationSlice.reducer;