import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import AddDestinationModal from "../../components/DestinationManagement/AddDestinationModal";
import DestinationGrid from "../../components/DestinationManagement/DestinationGrid";
import { fetchDestinations, saveDestinationForLater, saveDestinationAndContinue } from "../../services/api";
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setDestinations, setGridData, setLoading, setOriginatingCountry,  // Import the action
  selectOriginatingCountry
} from '../../store/destinationSlice';
import ActionButtons from '../../components/common/ActionButtons';
// import { Link } from "@connect/link";

const Container = styled.div`
  max-width: 800px;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
`;

const BackLink = styled.a`
  color: #f26e22;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 20px 0;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  margin: 20px 0 10px;
`;

const Description = styled.p`
  margin-bottom: 15px;
`;

const GuideLink = styled.a`
  display: inline-block;
  margin: 10px 0;
  padding: 8px 12px;
  color: #f26e22;
  font-weight: bold;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ddd;
`;

const AddButton = styled.button`
  display: inline-block;
  margin: 10px 0;
  padding: 10px 15px;
  color: #f26e22;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background-color: transparent;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SaveContinueButton = styled(Button)`
  background-color: #f26e22;
  cursor: pointer;
  color: white;
  border: none;
  &:hover {
    background-color: #e85f0c;
  }
`;

const SaveLaterButton = styled(Button)`
  background-color: white;
  border: 2px solid #f26e22;
  color: #f26e22;
  cursor: pointer;
  &:hover {
    background-color: #fff5f0;
  }
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #f26e22;
`;

const PaymentDestination = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, gridData } = useSelector(state => state.destinations);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [destinationCount, setDestinationCount] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [key, setKey] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationConfig, setConfirmationConfig] = useState({
    message: '',
    onConfirm: () => { }
  });

  const originatingCountry = useSelector(state => state.destinations.originatingCountry);

  useEffect(() => {
    dispatch(setOriginatingCountry('United States')); // Set the originating country value in future
    const loadData = async () => {
      dispatch(setLoading(true));
      try {
        const data = await fetchDestinations();
        dispatch(setDestinations(data));
      } catch (error) {
        console.error(error);
      }
      dispatch(setLoading(false));
    };
    loadData();
  }, [dispatch]);



  // Add this new function for showing confirmation modal
  const showConfirmationModal = (message, onConfirm) => {
    setConfirmationConfig({
      message,
      onConfirm
    });
    setShowConfirmation(true);
  };


  const handleAddOrUpdateDestination = async (isEdit = false) => {
    try {
      const data = await fetchDestinations();
      setDestinationCount(data.length);
      setKey(prevKey => prevKey + 1);

      toast.success(
        isEdit
          ? "Destination updated successfully!"
          : "Your Payment destination added successfully!"
      );
    } catch (error) {
      console.error("Error updating destinations:", error);
      toast.error("Failed to update destinations");
    }
  };

  const handleEdit = (countryData) => {
    setEditData(countryData);
    setIsModalOpen(true);
  };

  const handleSaveAndContinue = async () => {
    // Check if there's data in grid
    if (!gridData || gridData.length === 0) {
      toast.error("Please add at least one destination before continuing");
      return;
    }

    showConfirmationModal(
      "Would you like to save your destinations and continue to the next step?",
      async () => {
        try {
          dispatch(setLoading(true));
          const destinations = await fetchDestinations();
          await saveDestinationAndContinue(destinations);
          toast.success("Destinations saved successfully");

          // Refresh the grid data
          const updatedData = await fetchDestinations();
          setDestinationCount(updatedData.length);
          setKey(prevKey => prevKey + 1);
          dispatch(setLoading(false));
          history.push('/upload-document');
        } catch (error) {
          toast.error(error.message || "Failed to save destinations");
        } finally {
          dispatch(setLoading(false));
          setShowConfirmation(false);
        }
      }
    );
  };

  const handleSaveForLater = async () => {
    // Check if there's data in grid
    if (!gridData || gridData.length === 0) {
      toast.error("Please add at least one destination before saving");
      return;
    }

    showConfirmationModal(
      "Would you like to save your current destinations for later?",
      async () => {
        try {
          dispatch(setLoading(true));
          const destinations = await fetchDestinations();
          console.log("destinations for save for later", destinations);
          await saveDestinationForLater(destinations);
          toast.success("Destinations saved for later successfully");

          // Refresh the grid data
          const updatedData = await fetchDestinations();
          setDestinationCount(updatedData.length);
          setKey(prevKey => prevKey + 1);

          setTimeout(() => history.push('/upload-document'), 0);
        } catch (error) {
          toast.error(error.message || "Failed to save for later");
        } finally {
          dispatch(setLoading(false));
          setShowConfirmation(false);
        }
      }
    );
  };

  const handleCancel = () => {
    showConfirmationModal(
      "Are you sure you want to cancel? All destination data will be lost.",
      () => {
        // Clear Redux store
        dispatch(setGridData([]));

        // Navigate back
        // history.push('/pricing-contract');
      }
    );
  };

  return (
    <Container>
      <Toaster
        position="top-right"
        toastOptions={{
          success: { style: { background: 'green', color: '#fff' } },
          error: { style: { background: 'red', color: '#fff' } },
        }}
      />

      <div>
        <BackLink href="/pricing-contract">← Overview - Pricing and contract</BackLink>
      </div>

      <Title>Payment destination</Title>
      <Description>
        Follow the steps below to select where you'd like your payments to go.
        By selecting those destinations... totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae.
      </Description>
      <Description>
        Brief description of Business Endpoint Guide (BEG) goes here. Sed ut
        perspiciatis unde omnis iste natus error sit accusantium doloremque
        laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
        veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      </Description>

      <GuideLink href="/download-guide">
        📄 Download Business Endpoint Guide
      </GuideLink>

      <Divider />

      <SubTitle>Where would you like your payments to go?</SubTitle>
      <Description>
        There are many different countries that companies can send their
        payments to with Mastercard. Let us know the details of your payment
        preferences by adding your destination(s).
      </Description>

      <AddButton
        onClick={() => {
          setEditData(null);
          setIsModalOpen(true);
        }}
        disabled={isLoading}
      >
        ➕ {destinationCount === 0 ? "Add a destination country" : "Add another destination country"}
      </AddButton>

      <DestinationGrid
        key={key}
        onEdit={handleEdit}
        showConfirmation={showConfirmationModal}
      />

      <Divider />

      <ActionButtons
        onSaveContinue={handleSaveAndContinue}
        onSaveLater={handleSaveForLater}
        onCancel={handleCancel}
        disabled={isLoading}
      />

      {isModalOpen && (
        <AddDestinationModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleAddOrUpdateDestination}
          editData={editData}
        />
      )}

      <ConfirmationModal
        isOpen={showConfirmation}
        message={confirmationConfig.message}
        onConfirm={() => {
          confirmationConfig.onConfirm();
          setShowConfirmation(false);
        }}
        onCancel={() => setShowConfirmation(false)}
      />
    </Container>
  );
};

export default PaymentDestination;
