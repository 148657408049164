import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SectionDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 8px 0;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid #ddd;
  margin: 32px 0;
`;

const CustomerDetailsForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
  const dispatch = useDispatch();
  const formData = useSelector(selectStepData('customer'));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateStepData({
      step: 'customer',
      data: { [name]: value }
    }));
  };

  return (
    <>
      <FormSection>
        <SectionTitle>CUSTOMER DETAILS</SectionTitle>
        <SectionDescription>
          Brief description of forms goes here... For more information about the form
        </SectionDescription>

        <FormRow>
          <FormGroup>
            <Label>State of business name</Label>
            <Input
              name="businessName"
              value={formData.businessName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Register number</Label>
            <Input
              name="registerNumber"
              value={formData.registerNumber}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormRow>

        {/* Business Registration Details */}
        <FormRow>
          <FormGroup>
            <Label>Date of registration</Label>
            <Input
              type="date"
              name="dateOfRegistration"
              value={formData.dateOfRegistration}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Business country</Label>
            <Input
              name="businessCountry"
              value={formData.businessCountry}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormRow>

        {/* Trading Email */}
        <FormRow>
          <FormGroup>
            <Label>Trading email website</Label>
            <Input
              type="email"
              name="tradingEmail"
              value={formData.tradingEmail}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormRow>
      </FormSection>

      {/* Stock Exchange Section */}
      <FormSection>
        <SectionTitle>Stock Exchange</SectionTitle>
        <SectionDescription>
          Is customer listed on the regulated stock exchange?
        </SectionDescription>

        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="isListed"
              value="no"
              checked={formData.isListed === 'no'}
              onChange={handleInputChange}
            />
            No, they're not listed on the regulated stock exchange
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="isListed"
              value="yes"
              checked={formData.isListed === 'yes'}
              onChange={handleInputChange}
            />
            Yes, they're listed on the regulated stock exchange
          </RadioLabel>
        </RadioGroup>

        {formData.isListed === 'yes' && (
          <FormRow>
            <FormGroup>
              <Label>Name of exchange</Label>
              <Input
                name="stockName"
                value={formData.stockName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Stock code</Label>
              <Input
                name="stockCode"
                value={formData.stockCode}
                onChange={handleInputChange}
              />
            </FormGroup>
          </FormRow>
        )}
      </FormSection>
      <FormSection>
        <SectionTitle>Financial entity</SectionTitle>
        <SectionDescription>Is customer a US regulated financial entity?</SectionDescription>

        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="isRegulated"
              value="no"
              checked={formData.isRegulated === 'no'}
              onChange={handleInputChange}
            />
            No, they're not a US regulated financial entity
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="isRegulated"
              value="yes"
              checked={formData.isRegulated === 'yes'}
              onChange={handleInputChange}
            />
            Yes, they are a US regulated financial entity
          </RadioLabel>
        </RadioGroup>

        {formData.isRegulated === 'yes' && (
          <FormGroup>
            <Label>Name of US federal regulator</Label>
            <Input
              name="regulatorName"
              value={formData.regulatorName}
              onChange={handleInputChange}
            />
          </FormGroup>
        )}
      </FormSection>

      <FormSection>
        <SectionTitle>Media</SectionTitle>
        <SectionDescription>
          Is customer aware of any adverse media available on open sources that may implicate it's engagement with Transaction Services?
        </SectionDescription>

        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="hasAdverseMedia"
              value="no"
              checked={formData.hasAdverseMedia === 'no'}
              onChange={handleInputChange}
            />
            No, they're not aware of adverse media that may implicate Transaction Services
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="hasAdverseMedia"
              value="yes"
              checked={formData.hasAdverseMedia === 'yes'}
              onChange={handleInputChange}
            />
            Yes, they are aware of adverse media that could implicate Transaction Services
          </RadioLabel>
        </RadioGroup>

        {formData.hasAdverseMedia === 'yes' && (
          <FormGroup>
            <Label>Provide details</Label>
            <TextArea
              name="mediaDetails"
              value={formData.mediaDetails}
              onChange={handleInputChange}
              placeholder="Placeholder"
            />
          </FormGroup>
        )}
      </FormSection>

      <FormSection>
        <SectionTitle>Regulatory</SectionTitle>
        <SectionDescription>
          Within the past 5 years has the customer been subject to any regulatory fines or actions related to money laundering schemes, fraud, corrupt practices/bribery violations or associations with terrorists?
        </SectionDescription>

        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="hasRegulatoryFines"
              value="no"
              checked={formData.hasRegulatoryFines === 'no'}
              onChange={handleInputChange}
            />
            No, they've not been subject to any regulatory fines
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="hasRegulatoryFines"
              value="yes"
              checked={formData.hasRegulatoryFines === 'yes'}
              onChange={handleInputChange}
            />
            Yes, they have been subject to regulatory fines
          </RadioLabel>
        </RadioGroup>

        <Divider />

        <FormSection>
          <SectionTitle>Additional information</SectionTitle>

          <FormGroup>
            <Label>Regulator website</Label>
            <Input
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="www.regulator.com"
            />
          </FormGroup>

          <FormGroup>
            <Label>Bank ID number</Label>
            <Input
              name="bankId"
              value={formData.bankId}
              onChange={handleInputChange}
              placeholder="2234567"
            />
            <small style={{ color: '#666', fontSize: '12px' }}>
              Can be either National Clearing system number, SWIFT/BIC Code, Bank's IBAN number
            </small>
          </FormGroup>

          <FormGroup>
            <Label>Bank ID number type</Label>
            <Select
              name="bankIdType"
              value={formData.bankIdType}
              onChange={handleInputChange}
            >
              <option value="">Select option</option>
              <option value="national">National Clearing</option>
              <option value="swift">SWIFT/BIC</option>
              <option value="iban">IBAN</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Mastercard ICA</Label>
            <Input
              name="masterIca"
              value={formData.masterIca}
              onChange={handleInputChange}
              placeholder="XXXXXXXXXX"
            />
          </FormGroup>

          <FormGroup>
            <Label>Customer ID</Label>
            <Input
              name="customerId"
              value={formData.customerId}
              onChange={handleInputChange}
              placeholder="XXXXXXXXXX"
            />
          </FormGroup>
        </FormSection>
      </FormSection>

      <ActionButtons
        onSaveContinue={onSaveContinue}
        onSaveLater={onSaveLater}
        onCancel={onCancel}
      />
    </>
  );
};

export default CustomerDetailsForm;