import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { selectCurrentStep, selectStepData } from '../../../store/complianceSlice';

const HeaderSection = styled.div`
  padding: 24px 32px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackLink = styled(Link)`
  color: #f26e22;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const Title = styled.h1`
  font-size: 28px;
  margin: 0 0 8px 0;
  font-weight: 500;
`;

const Description = styled.p`
  color: #666;
  font-size: 14px;
  margin: 0;
`;

const ExportButton = styled.button`
  padding: 8px 16px;
  background: transparent;
  border: none;
  color: #f26e22;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  &:after {
    content: '▼';
    font-size: 12px;
  }
`;

const WolfsbergHeader = ({ title, description }) => {
  const currentStep = useSelector(selectCurrentStep);
  const formData = useSelector(selectStepData(currentStep));

  const handleExport = () => {
    const stepLabel = currentStep;

    // Create the export data object with metadata
    const exportData = {
      stepId: currentStep,
      stepName: stepLabel,
      exportDate: new Date().toISOString(),
      data: formData,
    };

    // Convert to formatted JSON string
    const jsonString = JSON.stringify(exportData, null, 2);

    // Create and trigger download
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${currentStep}-form-data.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <HeaderSection>
      <HeaderLeft>
        <BackLink to="/required-documents">← Overview - Customer profile</BackLink>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </HeaderLeft>
      <ExportButton onClick={handleExport}>
        Export
      </ExportButton>
    </HeaderSection>
  );
};

export default WolfsbergHeader;