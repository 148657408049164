import {
    selectCurrentStep as selectComplianceStep,
    selectCompletedSteps as selectComplianceCompleted
} from '../../../store/complianceSlice';
import {
    selectCurrentStep as selectWolfsbergStep,
    selectCompletedSteps as selectWolfsbergCompleted
} from '../../../store/wolfsbergSlice';

export const STEP_CONFIGURATIONS = {
    compliance: {
        steps: [
            { id: 'customer', label: 'Customer details' },
            { id: 'ownership', label: 'Ownership and control' },
            { id: 'funding', label: 'Customer funding' },
            { id: 'declaration', label: 'Declaration of business' },
            { id: 'contacts', label: 'Customer contacts' },
            { id: 'acknowledgement', label: 'Customer acknowledgement' }
        ],
        selectors: {
            currentStep: selectComplianceStep,
            completedSteps: selectComplianceCompleted
        }
    },
    wolfsberg: {
        steps: [
            { id: 'entity', label: 'Entity and ownership' },
            { id: 'amlctf', label: 'AML,CTF and sanctions program' },
            { id: 'antibribery', label: 'Anti bribery and corruption' },
            { id: 'amlpolicies', label: 'AML,CTF and sanctions policies' },
            { id: 'kyc', label: 'KYC, CDD and EDD' },
            { id: 'monitoring', label: 'Monitoring and reporting' },
            { id: 'payment', label: 'Payment transparency' },
            { id: 'sanctions', label: 'Sanctions' },
            { id: 'training', label: 'Training and education' },
            { id: 'audit', label: 'Audit' }
        ],
        selectors: {
            currentStep: selectWolfsbergStep,
            completedSteps: selectWolfsbergCompleted
        }
    }
    // New form types can be added here following the same structure
};

export const getStepIndex = (steps, stepId) => {
    return steps.findIndex(step => step.id === stepId);
};

export const getNextStep = (steps, currentStepId) => {
    const currentIndex = getStepIndex(steps, currentStepId);
    return currentIndex < steps.length - 1 ? steps[currentIndex + 1].id : null;
};

export const getPreviousStep = (steps, currentStepId) => {
    const currentIndex = getStepIndex(steps, currentStepId);
    return currentIndex > 0 ? steps[currentIndex - 1].id : null;
};