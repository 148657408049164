import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import { useHistory } from 'react-router-dom';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
  text-transform: uppercase;
`;

const LegalText = styled.p`
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 60%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const LegalAcknowledgement = styled.div`
  background-color: #FFF5F0;
  border-top: 2px solid #f26e22;
  padding: 24px;
  margin-top: 32px;
  width: 70%;
`;

const ComplianceReviewSection = styled(LegalAcknowledgement)`
  margin-top: 24px;
`;

const LegalSectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #D85604;  // Russet orange color
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  font-weight: 800;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;
`;

const Checkbox = styled.input`
  margin-top: 3px;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #333;
  line-height: 1.4;
`;

const Divider = styled.hr`
 border: 0;
  border-top: 1px solid #eee;
 margin: 32px 0;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
  margin-top: 8px;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const CommentsLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 16px;
`;

const CommentDisplayContainer = styled.div`
  margin-top: 24px;
  padding: 20px;
  background-color: #fff;
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
`;

const CommentTitle = styled.h3`
  font-size: 18px;
  margin: 0;
`;

const ChangesBadge = styled.span`
  background-color: #fde8e8;
  color: #e53e3e;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
`;

const CommentDetails = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  color: #666;
`;

const CommentContent = styled.p`
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin: 0;
`;

const CustomerAcknowledgementForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formData = useSelector(selectStepData('acknowledgement'));
  const [showSubmittedComment, setShowSubmittedComment] = useState(false);


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    dispatch(updateStepData({
      step: 'acknowledgement',
      data: { [name]: inputValue }
    }));

    setShowSubmittedComment(false);
  };

  const handleSaveAndContinue = () => {
    if (formData?.isCorrect === 'no' && formData?.comments) {
      setShowSubmittedComment(true);
    }
    onSaveContinue();
    history.push('/next-page');
  };

  return (
    <>
      <FormSection>
        <SectionTitle>CUSTOMER ACKNOWLEDGEMENT</SectionTitle>

        <LegalText>
          The undersigned acknowledge that they have reviewed this document on behalf of the entity nominated herein
          (the "Customer") and that all information provided herein is true, complete, and accurate to the best of their
          knowledge. The Customer, by and through the undersigned, certifies that all information set forth in this completed
          form is true and correct to the best of {"{"}eh Customer's knowledge.
        </LegalText>

        <LegalText>
          The undersigned agrees that all references named in this form, banks, and consumer reporting agencies, may
          release all personal and business credit financial information to Mastercard Transaction Services and its Affiliates,
          and its third-party subcontractors and/or agents (collectively "MTS"). The undersigned authorizes MTS to verify the
          information in this form and to request reports fro consumer reporting agencies, as MTS deem necessary, for any
          purpose designed to achieve the objectives of the due diligence process.
        </LegalText>

        <LegalText>
          The undersigned authorizes MTS, its Affiliates, and its third-party subcontractors and/or agents ot exchange
          amongst each other the information contained in this form, including personal information about any party
          undersigned herein and any information received from all references, banks, and consumer reporting agencies for
          any purpose permitted by law. It is MTS policy to obtain certain information to verify the identities of individuals
          and entities named herein while processing the information in this form.
        </LegalText>

        <LegalText>
          The undersigned herein authorizes MTS, its Affiliates, and third parties authorized by MTS, to obtain subsequent
          consumer reports and any other information to review, assess, maintain, and update records in MTS's possession,
          or for any other purpose that may be required and permitted by law, and, if necessary, to disclose such information
          amongst MTS's subsidiaries and associated companies.
        </LegalText>

        <LegalText>
          Mastercard makes no representations or warranties of any kind herein, expressed, or implied, with respect to the
          contents of this form and no responsibility of liability is or will be accepted by Mastercard or aby any of its
          directors, officers, or employees as to or in relation to the completeness or accuracy of this form or for any reliance
          placed on this form by any person.
        </LegalText>

        <LegalText>
          The precise terms of the Customer's use of Mastercard Cross-Border Services, and the respective rights and
          obligations of Mastercard and the Customer in connection therewith, shall be subject to the terms of a separate
          written agreement.
        </LegalText>

        <LegalText>
          This form and the information contained in this form is proprietary and confidential to Mastercard and is intended
          to be for the use of the Customer designated above only. By accepting this form, the Customer agrees that this
          document may not be copied, duplicated, published, or disclosed, in whole or in part, without the prior written
          permission of Mastercard. Any disclosure, reproduction, distribution or other use of this form or any information
          within this form, in whole or in part, is prohibited.
        </LegalText>

        <LegalText>
          Cross-Border Services may be provided by Mastercard Transaction Services Corp. or through its subsidiaries and
          affiliates. In some jurisdictions, services may be provided by subsidiaries or affiliates that hold licenses to engage in
          money transmission. For a list fo those jurisdictions, see crossborder.mastercard.com/licenses. Terms and
          conditions may apply. Services are subject to availability and certain restrictions, and Mastercard reserves the right
          to change, from time to time, in Mastercard's sole discretion, the design, operation and functionalities of, and
          services comprising, the Cross-Border Services.
        </LegalText>

        <LegalText>
          Alternate designs, operations, and functionalities of, and services comprising Cross-Border Services may be
          available, from time to time, to participants on separate terms outside of what is represented here. The
          availability, operations, and functionalities of, and services comprising, Cross-Border Services may vary by location.
          Mastercard makes no representations as to any aspect of the service provided by third parties, NMLS ID# 900705.
          Licensed as a Money Transmitter by the New York State Department of Financial Services.
        </LegalText>

        <Divider />

        <FormGroup>
          <Label>Full legal name of applicant</Label>
          <Input
            name="legalName"
            value={formData?.legalName || ''}
            onChange={handleInputChange}
            placeholder="Full legal name"
          />
        </FormGroup>

        <FormGroup>
          <Label>Title/role</Label>
          <Input
            name="titleRole"
            value={formData?.titleRole || ''}
            onChange={handleInputChange}
            placeholder="Title/role"
          />
        </FormGroup>

        <FormGroup>
          <Label>Date</Label>
          <Input
            type="date"
            name="date"
            value={formData?.date || ''}
            onChange={handleInputChange}
            placeholder="DD MMM YYYY"
          />
        </FormGroup>

        <Divider />

        <LegalAcknowledgement>
          <LegalSectionTitle>LEGAL ACKNOWLEDGEMENT</LegalSectionTitle>
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              id="legalConfirmation"
              name="legalConfirmation"
              checked={formData?.legalConfirmation || false}
              onChange={handleInputChange}
            />
            <CheckboxLabel htmlFor="legalConfirmation">
              I confirm that I am the authorized Compliance representative of my company.
            </CheckboxLabel>
          </CheckboxContainer>
        </LegalAcknowledgement>
        {formData?.legalConfirmation && (
          <ComplianceReviewSection>
            <LegalSectionTitle>COMPLIANCE REVIEW</LegalSectionTitle>
            <Label>Does everything look correct?</Label>
            <RadioGroup>
              <RadioLabel>
                <input
                  type="radio"
                  name="isCorrect"
                  value="yes"
                  checked={formData?.isCorrect === 'yes'}
                  onChange={handleInputChange}
                />
                Yes, everything looks correct and I approve
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name="isCorrect"
                  value="no"
                  checked={formData?.isCorrect === 'no'}
                  onChange={handleInputChange}
                />
                No, I'd like to request changes
              </RadioLabel>
            </RadioGroup>

            {formData?.isCorrect === 'no' && (
              <>
                <CommentsLabel>Comments</CommentsLabel>
                <TextArea
                  name="comments"
                  value={formData?.comments || ''}
                  onChange={handleInputChange}
                  placeholder="Describe the detailed changes you would like the applicant to make for this section"
                />
              </>
            )}
          </ComplianceReviewSection>
        )}
      </FormSection>

      {showSubmittedComment && formData?.isCorrect === 'no' && (
        <CommentDisplayContainer>
          <CommentHeader>
            <CommentTitle>Compliance requested changes</CommentTitle>
            <ChangesBadge>Changes requested</ChangesBadge>
          </CommentHeader>
          <CommentDetails>
            <div>Compliance reviewer <span>{new Date().toLocaleDateString('en-US', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            })}, {new Date().toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })}</span></div>
            {/* <div>Step: Declaration of business</div>
                        <div>Section: Relationship purpose</div> */}
          </CommentDetails>
          <CommentContent>
            Comment: {formData?.comments}
          </CommentContent>
        </CommentDisplayContainer>
      )}

      <ActionButtons
        onSaveContinue={handleSaveAndContinue}
        onSaveLater={onSaveLater}
        onCancel={onCancel}
        disabled={formData?.isCorrect === 'no' && !formData?.comments}
      />
    </>
  );
};

export default CustomerAcknowledgementForm;