import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { STEP_CONFIGURATIONS } from './StepConfigurations';

const StepsContainer = styled.div`
  width: 250px;
  padding: 20px;
  position: relative;
`;

const StepsList = styled.div`
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 35px;
    width: 2px;
    height: calc(100% - 60px);
    background-color: #E0E0E0;
    z-index: 1;
  }
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 0;
  cursor: ${props => props.canClick ? 'pointer' : 'default'};
  position: relative;
  z-index: 2;
  opacity: ${props => props.isDisabled ? 0.5 : 1};
  
  ${props => props.isActive && `
    font-weight: 500;
    color: #333;
  `}
  
  ${props => !props.isActive && `
    color: #888;
  `}
`;

const StepIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${props =>
    props.isCompleted ? '#4CAF50' :
      props.isActive ? '#f26e22' :
        '#E0E0E0'
  };
  background-color: ${props =>
    props.isCompleted ? '#4CAF50' :
      'white'
  };
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  ${props => props.isCompleted && `
    &::after {
      content: '✓';
      color: white;
      font-size: 14px;
    }
  `}
  
  ${props => props.isActive && !props.isCompleted && `
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #000;
      border-radius: 50%;
    }
  `}
`;

const StepLabel = styled.span`
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  padding: 16px;
  text-align: center;
  font-size: 14px;
`;

const ProgressSteps = ({
  type = 'compliance',
  onStepClick,
  allowNavigation = true,
  customStyles = {}
}) => {
  // Get configuration for the specified form type
  const config = STEP_CONFIGURATIONS[type];

  // Get current state using the configured selectors
  // Use default selectors if config is not found to avoid hook errors
  const currentStep = useSelector(config?.selectors?.currentStep || (() => null));
  const completedSteps = useSelector(config?.selectors?.completedSteps || (() => []));

  if (!config) {
    return (
      <ErrorMessage>
        Configuration not found for form type: {type}
      </ErrorMessage>
    );
  }

  const handleStepClick = (stepId) => {
    if (!allowNavigation) return;

    if (onStepClick && (completedSteps.includes(stepId) || stepId === currentStep)) {
      onStepClick(stepId);
    }
  };

  return (
    <StepsContainer style={customStyles.container}>
      <StepsList style={customStyles.list}>
        {config.steps.map((step) => {
          const isCompleted = completedSteps.includes(step.id);
          const isActive = currentStep === step.id;
          const canClick = allowNavigation && (isCompleted || step.id === currentStep);

          return (
            <StepItem
              key={step.id}
              isActive={isActive}
              canClick={canClick}
              onClick={() => handleStepClick(step.id)}
              style={customStyles.item}
            >
              <StepIcon
                isActive={isActive}
                isCompleted={isCompleted}
                style={customStyles.icon}
              />
              <StepLabel style={customStyles.label}>
                {step.label}
              </StepLabel>
            </StepItem>
          );
        })}
      </StepsList>
    </StepsContainer>
  );
};

export default ProgressSteps;