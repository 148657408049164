import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/wolfsbergSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
  text-transform: uppercase;
`;

const Question = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  line-height: 1.5;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
  
  input[type="radio"] {
    margin: 0;
    width: 16px;
    height: 16px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
  
  input[type="checkbox"] {
    margin-top: 2px;
    width: 16px;
    height: 16px;
  }
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid #eee;
  margin: 32px 0;
`;

const AntiBriberyForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('antibribery')) || {};

    const trainingOptions = [
        'Board and Senior Committee Management',
        '1st Line of Defence',
        '2nd Line of Defence',
        '3rd Line of Defence',
        'Third parties to which specific compliance activities subject to ABC risk have been outsourced',
        'Non-employed workers as appropriate (contractors/consultants)'
    ];

    const handleInputChange = (key, value) => {
        dispatch(updateStepData({
            step: 'antibribery',
            data: { [key]: value }
        }));
    };

    const handleCheckboxChange = (option) => {
        const currentSelected = formData.mandatoryTraining || [];
        const newSelected = currentSelected.includes(option)
            ? currentSelected.filter(item => item !== option)
            : [...currentSelected, option];

        handleInputChange('mandatoryTraining', newSelected);
    };

    return (
        <>
            <FormSection>
                <SectionTitle>ANTI BRIBERY AND CORRUPTION</SectionTitle>

                <Question>
                    Has the Entity documented policies and procedures consistent with applicable ABC regulations and requirements to reasonably
                    prevent, detect and report bribery and corruption?
                </Question>
                <RadioGroup>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasDocumentedPolicies"
                            value="no"
                            checked={formData.hasDocumentedPolicies === 'no'}
                            onChange={() => handleInputChange('hasDocumentedPolicies', 'no')}
                        />
                        No
                    </RadioLabel>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasDocumentedPolicies"
                            value="yes"
                            checked={formData.hasDocumentedPolicies === 'yes'}
                            onChange={() => handleInputChange('hasDocumentedPolicies', 'yes')}
                        />
                        Yes
                    </RadioLabel>
                </RadioGroup>

                <Question>
                    Does the Entity have an enterprise wide program that sets minimum ABC standards?
                </Question>
                <RadioGroup>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasEnterpriseProgram"
                            value="no"
                            checked={formData.hasEnterpriseProgram === 'no'}
                            onChange={() => handleInputChange('hasEnterpriseProgram', 'no')}
                        />
                        No
                    </RadioLabel>
                    <RadioLabel>
                        <input
                            type="radio"
                            name="hasEnterpriseProgram"
                            value="yes"
                            checked={formData.hasEnterpriseProgram === 'yes'}
                            onChange={() => handleInputChange('hasEnterpriseProgram', 'yes')}
                        />
                        Yes
                    </RadioLabel>
                </RadioGroup>

                <Question>
                    Does the Entity provide mandatory ABC training to:
                </Question>
                <CheckboxGroup>
                    {trainingOptions.map((option) => (
                        <CheckboxLabel key={option}>
                            <input
                                type="checkbox"
                                checked={(formData.mandatoryTraining || []).includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            {option}
                        </CheckboxLabel>
                    ))}
                </CheckboxGroup>
            </FormSection>

            <Divider />

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default AntiBriberyForm;