// config/complianceSteps.js
export const COMPLIANCE_STEPS = [
    {
        id: 'customer',
        label: 'Customer details'
    },
    {
        id: 'ownership',
        label: 'Ownership and control'
    },
    {
        id: 'funding',
        label: 'Customer funding'
    },
    {
        id: 'declaration',
        label: 'Declaration of business'
    },
    {
        id: 'contacts',
        label: 'Customer contacts'
    },
    {
        id: 'acknowledgement',
        label: 'Customer acknowledgement'
    }
];

export const getStepIndex = (stepId) => {
    return COMPLIANCE_STEPS.findIndex(step => step.id === stepId);
};

export const getNextStep = (currentStepId) => {
    const currentIndex = getStepIndex(currentStepId);
    return currentIndex < COMPLIANCE_STEPS.length - 1
        ? COMPLIANCE_STEPS[currentIndex + 1].id
        : null;
};

export const getPreviousStep = (currentStepId) => {
    const currentIndex = getStepIndex(currentStepId);
    return currentIndex > 0
        ? COMPLIANCE_STEPS[currentIndex - 1].id
        : null;
};