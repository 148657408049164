import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ProgressSteps from '../../components/common/ProgressSteps/ProgressSteps';
import { getNextStep } from '../../components/common/ProgressSteps/WolfsbergSteps';
import {
    selectCurrentStep,
    setCurrentStep,
    completeStep,
    selectAllStepsData,
    saveProgress
} from '../../store/wolfsbergSlice';
import EntityOwnershipForm from './EntityOwnershipForm';
import WolfsbergHeader from '../../components/common/WolfsbergHeader/WolfsbergHeader';
import AMLCTFForm from './AMLCTFSanctionPrograms';
import AntiBriberyForm from './AntiBriberyForm';
import AMLPoliciesForm from './AMLCTFSanctionPoliciesForm';
// import KYCForm from './KYCForm';
// import MonitoringForm from './MonitoringForm';
// import PaymentTransparencyForm from './PaymentTransparencyForm';
// import SanctionsForm from './SanctionsForm';
// import TrainingForm from './TrainingForm';
// import AuditForm from './AuditForm';
// import FraudForm from './FraudForm';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 32px;
  margin-bottom: 80px;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  position: relative;
`;

const Wolfsberg = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentStep = useSelector(selectCurrentStep);
    const stepsData = useSelector(selectAllStepsData);

    const handleStepClick = (stepId) => {
        dispatch(setCurrentStep(stepId));
    };

    const handleSaveAndContinue = async () => {
        try {
            console.log(`Saving ${currentStep} data:`, stepsData);
            dispatch(completeStep(currentStep));
            dispatch(saveProgress());

            const nextStep = getNextStep(currentStep);
            if (nextStep) {
                dispatch(setCurrentStep(nextStep));
            } else {
                console.log('Wolfsberg process completed!');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleSaveForLater = async () => {
        try {
            dispatch(saveProgress());
            localStorage.setItem('wolfsbergLastStep', currentStep);
            history.push('/dashboard');
        } catch (error) {
            console.error('Error saving for later:', error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const renderFormContent = () => {
        const commonProps = {
            onSaveContinue: handleSaveAndContinue,
            onSaveLater: handleSaveForLater,
            onCancel: handleCancel
        };

        switch (currentStep) {
            case 'entity':
                return <EntityOwnershipForm {...commonProps} />;
            case 'amlctf':
                return <AMLCTFForm {...commonProps} />;
            case 'antibribery':
                return <AntiBriberyForm {...commonProps} />;
            case 'amlpolicies':
                return <AMLPoliciesForm {...commonProps} />;
            default:
                return null;
        }
    };

    return (
        <PageContainer>
            <WolfsbergHeader
                title="Wolfsberg"
                description="Brief descriptions of forms goes here..."
            />
            <ContentWrapper>
                <ProgressSteps type="wolfsberg" onStepClick={handleStepClick} />
                <MainContent>
                    {renderFormContent()}
                </MainContent>
            </ContentWrapper>
        </PageContainer>
    );
};

export default Wolfsberg;