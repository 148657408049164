import React, { useState, useEffect, useCallback } from "react";
import { fetchCountries, fetchDestinations, fetchServicesByCountry, saveDestination, updateDestination } from "../../services/api";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { toast, Toaster } from "react-hot-toast";
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { setGridData } from "../../store/destinationSlice";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  z-index: 1000;
  max-height: 70vh;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #666;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
  margin: 0 0 32px 0;
  font-weight: normal;
`;

const Label = styled.div`
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
  font-weight: normal;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: white;
  cursor: pointer;
  &:hover {
    border-color: #999;
  }
  &:focus {
    outline: none;
    border-color: #666;
  }
`;

const ServicesSection = styled.div`
  margin-top: 24px;
  width: 93%;
`;

const ServicesTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin: 0 0 16px 0;
  font-weight: normal;
`;

const ServiceContainer = styled.div`
  margin-bottom: 16px;
`;

const ServiceCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    cursor: pointer;
  }

  label {
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
`;

const PayoutSection = styled.div`
  margin-left: 32px;
  margin-top: 16px;
`;

const PayoutLabel = styled.div`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 10px 24px;
  border-radius: 24px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #D85604;
  
  &:first-child {
    background-color: #D85604;
    color: white;
    &:hover {
      background-color: #c24c03;
    }
  }
  
  &:last-child {
    background-color: white;
    color: #D85604;
    
    &:hover {
      background-color: #fff5f0;
    }
  }
`;

const AddDestinationModal = ({ onClose, onSubmit, editData }) => {
    const dispatch = useDispatch();
    const { gridData } = useSelector(state => state.destinations);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedPayouts, setSelectedPayouts] = useState({});
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isMounted, setIsMounted] = useState(true);



    useEffect(() => {
        const loadCountries = async () => {
            const countriesData = await fetchCountries();
            const formattedCountries = countriesData.map(country => ({
                countryCode: country.countryCode || `code-${country.countryName}`, // Fallback if no country code
                countryName: country.countryName
            }));
            // Filter out countries that are already in the grid
            const availableCountries = formattedCountries.filter(
                (country) => !gridData.some((item) => item.country === country.countryName)
            );
            setCountries(availableCountries);
            if (editData) {
                // Set selected country
                setSelectedCountry(editData.country);

                // Fetch services for the country
                const servicesData = await fetchServicesByCountry(editData.country);
                setServices(servicesData);

                // Group services by name
                const serviceGroups = {};
                editData.services.forEach(service => {
                    if (!serviceGroups[service.service]) {
                        serviceGroups[service.service] = true;
                    }
                });
                setSelectedServices(Object.keys(serviceGroups));

                // Set payout options
                const payoutOptions = {};
                Object.keys(serviceGroups).forEach(serviceName => {
                    const serviceData = editData.services.filter(s => s.service === serviceName);
                    payoutOptions[serviceName] = {
                        currencies: [...new Set(serviceData.map(s => s.payoutCurrency))],
                        paymentTypes: [...new Set(serviceData.map(s => s.payoutType))]
                    };
                });
                setSelectedPayouts(payoutOptions);
            }
        };
        loadCountries();
        // Cleanup function to cancel any ongoing asynchronous tasks
        return () => {
            setIsMounted(false);
        };
    }, [editData, gridData, dispatch]);

    // const handleCountryChange = async (countryName) => {
    //     setSelectedCountry(countryName);

    //     try {
    //         const servicesData = await fetchServicesByCountry(countryName);
    //         setServices(servicesData);
    //     } catch (error) {
    //         console.error("Error fetching services:", error);
    //         setServices([]);
    //         toast.error("Error loading services for selected country");
    //     }

    //     setSelectedServices([]);
    //     setSelectedPayouts({});
    // };
    const handleCountryChange = useCallback(async (countryName) => {
        setSelectedCountry(countryName);

        try {
            const servicesData = await fetchServicesByCountry(countryName);
            if (isMounted) {
                setServices(servicesData);
            }
        } catch (error) {
            console.error("Error fetching services:", error);
            if (isMounted) {
                setServices([]);
                toast.error("Error loading services for selected country");
            }
        }

        setSelectedServices([]);
        setSelectedPayouts({});
    }, [isMounted]);
    const handleServiceSelection = (serviceName, isChecked) => {
        if (isChecked) {
            setSelectedServices((prev) => [...prev, serviceName]);
        } else {
            setSelectedServices((prev) => prev.filter((name) => name !== serviceName));
            setSelectedPayouts((prev) => {
                const updated = { ...prev };
                delete updated[serviceName];
                return updated;
            });
        }
    };

    const handleDropdownChange = (serviceName, type, selected) => {
        setSelectedPayouts((prev) => ({
            ...prev,
            [serviceName]: {
                ...prev[serviceName],
                [type]: selected,
            },
        }));
    };

    const handleSubmit = async () => {
        try {
            if (!selectedCountry) {
                toast.error("Please select a country");
                return;
            }

            if (selectedServices.length === 0) {
                toast.error("Please select at least one service");
                return;
            }

            const hasIncompleteServices = selectedServices.some(serviceName => {
                const payoutOptions = selectedPayouts[serviceName];
                return !payoutOptions ||
                    !payoutOptions.currencies ||
                    payoutOptions.currencies.length === 0 ||
                    !payoutOptions.paymentTypes ||
                    payoutOptions.paymentTypes.length === 0;
            });

            if (hasIncompleteServices) {
                toast.error("Please complete payout options for all selected services");
                return;
            }

            const data = {
                country: selectedCountry,
                services: selectedServices.map((serviceName) => ({
                    name: serviceName,
                    payoutOptions: selectedPayouts[serviceName] || {},
                })),
            };

            if (editData) {
                await updateDestination(data);
            } else {
                await saveDestination(data);
            }

            // Fetch fresh data and update Redux store
            const freshData = await fetchDestinations();
            dispatch(setGridData(freshData));

            onSubmit(!!editData);
            onClose();

        } catch (error) {
            toast.error(error.message || "Failed to save destination");
        }
    };

    return (
        <>
            <Toaster position="top-right" />
            <ModalOverlay onClick={onClose} />
            <ModalContainer>
                <CloseButton onClick={onClose}>×</CloseButton>
                <Title>Add Destination Country</Title>

                <Label>Destination Country:</Label>
                <Select
                    onChange={(e) => handleCountryChange(e.target.value)}
                    value={selectedCountry}
                >
                    <option value="" key="default">Select Country</option>
                    {countries.map((country) => (
                        <option key={country.countryCode} value={country.countryName}>
                            {country.countryName}
                        </option>
                    ))}
                </Select>

                {services.length > 0 && (
                    <ServicesSection>
                        <ServicesTitle>Services</ServicesTitle>
                        {services.map((service) => (
                            <ServiceContainer key={service.serviceName}>
                                <ServiceCheckbox>
                                    <input
                                        type="checkbox"
                                        id={`service-${service.serviceName}`}
                                        value={service.serviceName}
                                        checked={selectedServices.includes(service.serviceName)}
                                        onChange={(e) => handleServiceSelection(
                                            service.serviceName,
                                            e.target.checked
                                        )}
                                    />
                                    <label htmlFor={`service-${service.serviceName}`}>
                                        {service.serviceName}
                                    </label>
                                </ServiceCheckbox>

                                {selectedServices.includes(service.serviceName) && (
                                    <PayoutSection>
                                        <PayoutLabel>Payout Currencies:</PayoutLabel>
                                        <MultiSelectDropdown
                                            options={service.currencies}
                                            selectedOptions={selectedPayouts[service.serviceName]?.currencies || []}
                                            isOpen={openDropdown === `currencies-${service.serviceName}`}
                                            toggleDropdown={() => setOpenDropdown(
                                                openDropdown === `currencies-${service.serviceName}`
                                                    ? null
                                                    : `currencies-${service.serviceName}`
                                            )}
                                            onChange={(selected) => handleDropdownChange(
                                                service.serviceName,
                                                "currencies",
                                                selected
                                            )}
                                        />

                                        <PayoutLabel>Payment Types:</PayoutLabel>
                                        <MultiSelectDropdown
                                            options={service.paymentTypes}
                                            selectedOptions={selectedPayouts[service.serviceName]?.paymentTypes || []}
                                            isOpen={openDropdown === `paymentTypes-${service.serviceName}`}
                                            toggleDropdown={() => setOpenDropdown(
                                                openDropdown === `paymentTypes-${service.serviceName}`
                                                    ? null
                                                    : `paymentTypes-${service.serviceName}`
                                            )}
                                            onChange={(selected) => handleDropdownChange(
                                                service.serviceName,
                                                "paymentTypes",
                                                selected
                                            )}
                                        />
                                    </PayoutSection>
                                )}
                            </ServiceContainer>
                        ))}
                    </ServicesSection>
                )}

                <ButtonContainer>
                    <Button onClick={handleSubmit}>{editData ? 'Update' : 'Add'}</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ButtonContainer>
            </ModalContainer>
        </>
    );
};

export default AddDestinationModal;
