import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ActionButtons from '../../components/common/ActionButtons';
import { selectIsSubmitting, selectSelectedDocuments, selectSelectedForms, setError, setIsSubmitting, updateSelectedDocuments, updateSelectedForms } from '../../store/requiredDocumentsSlice';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  padding: 32px;
  max-width: 800px;
`;

const BackLink = styled.div`
  display: flex;
  align-items: center;
  color: #f26e22;
  cursor: pointer;
  margin-bottom: 24px;
  font-weight: 500;

  &:before {
    content: '←';
    margin-right: 8px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Divider = styled.hr`
  margin: 0px;
  border: 0;
  border-top: 1px solid #ddd;
`;

const Description = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 32px;
`;

const Section = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const SectionDescription = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 24px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  cursor: pointer;

  input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const RequiredDocumentation = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // Replace useState with Redux selectors
    const selectedForms = useSelector(selectSelectedForms);
    const selectedDocuments = useSelector(selectSelectedDocuments);
    const isSubmitting = useSelector(selectIsSubmitting);

    const handleFormChange = (key) => {
        dispatch(updateSelectedForms({
            key,
            value: !selectedForms[key]
        }));
    };

    const handleDocumentChange = (key) => {
        dispatch(updateSelectedDocuments({
            key,
            value: !selectedDocuments[key]
        }));
    };

    const handleSubmit = async () => {
        try {
            dispatch(setIsSubmitting(true));

            // Add your API call here
            // const response = await api.submitDocumentation({
            //     selectedForms,
            //     selectedDocuments
            // });

            // Simulate API call
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Navigate to next step or show success message
            history.push('/wolfsberg');
        } catch (error) {
            dispatch(setError(error.message));
        } finally {
            dispatch(setIsSubmitting(false));
        }
    };

    const handleSaveForLater = async () => {
        try {
            dispatch(setIsSubmitting(true));

            // Add your save for later API call here
            // const response = await api.saveDocumentationProgress({
            //     selectedForms,
            //     selectedDocuments
            // });

            // Simulate API call
            await new Promise(resolve => setTimeout(resolve, 1000));

            history.push('/dashboard');
        } catch (error) {
            dispatch(setError(error.message));
        } finally {
            dispatch(setIsSubmitting(false));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Container>
            <BackLink onClick={() => history.push('/compliance')}>
                Overview - Compliance and Due Diligence
            </BackLink>

            <Title>Required documentation</Title>
            <Description>
                We'll need to collect several required documents. Make sure to submit all
                documentation in English.
            </Description>

            <Divider />

            <Section>
                <SectionTitle>Forms to be completed</SectionTitle>
                <SectionDescription>
                    Select the forms you'd like the customer to complete
                </SectionDescription>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedForms.wolfsberg_banks}
                            onChange={() => handleFormChange('wolfsberg_banks')}
                        />
                        Wolfsberg Questionnaire (for banks)
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedForms.wolfsberg_non_banks}
                            onChange={() => handleFormChange('wolfsberg_non_banks')}
                        />
                        Wolfsberg Questionnaire (for non-banks)
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedForms.b2b_enhanced}
                            onChange={() => handleFormChange('b2b_enhanced')}
                        />
                        B2B Enhanced Due Diligence Questionnaire
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedForms.reseller_enhanced}
                            onChange={() => handleFormChange('reseller_enhanced')}
                        />
                        Reseller Enhanced Due Diligence Questionnaire
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedForms.agent_enhanced}
                            onChange={() => handleFormChange('agent_enhanced')}
                        />
                        Agent/Sub Payer Enhanced Due Diligence Questionnaire
                    </CheckboxLabel>
                </CheckboxGroup>
            </Section>

            <Divider />

            <Section>
                <SectionTitle>Documents to be uploaded</SectionTitle>
                <SectionDescription>
                    Select the documents you'd like the customer to upload
                </SectionDescription>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedDocuments.power_of_attorney}
                            onChange={() => handleDocumentChange('power_of_attorney')}
                        />
                        Power of Attorney
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedDocuments.articles_of_incorporation}
                            onChange={() => handleDocumentChange('articles_of_incorporation')}
                        />
                        Articles of Incorporation
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedDocuments.business_registration}
                            onChange={() => handleDocumentChange('business_registration')}
                        />
                        Business registration
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedDocuments.financial_license}
                            onChange={() => handleDocumentChange('financial_license')}
                        />
                        Financial license authorizing the activity proposed
                    </CheckboxLabel>
                    <Divider />
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            checked={selectedDocuments.photo_id}
                            onChange={() => handleDocumentChange('photo_id')}
                        />
                        Photo ID for a C-level representative
                    </CheckboxLabel>
                </CheckboxGroup>
            </Section>

            <ActionButtons
                onSaveContinue={handleSubmit}
                onSaveLater={handleSaveForLater}
                onCancel={handleCancel}
                continueText="Submit"
            />
        </Container>
    );
};

export default RequiredDocumentation;