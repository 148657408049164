import React, { useState } from "react";
import styled from 'styled-components';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownHeader = styled.div`
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  width: 100%;

  &:hover {
    border-color: #999;
  }

  span {
    color: #666;
  }
`;

const DropdownMenu = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 4px;
`;

const DropdownControls = styled.div`
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
`;

const ControlButtonsRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  color: ${props => props.$primary ? '#D85604' : '#666'};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  input[type="radio"] {
    margin: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

const OptionsList = styled.div`
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  
  &:hover {
    background-color: #f5f5f5;
  }

  input[type="checkbox"] {
    margin: 0;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  label {
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
`;

const DoneButton = styled.button`
  background-color: #D85604;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  margin: 12px;

  &:hover {
    background-color: #c24c03;
  }
`;

const MultiSelectDropdown = ({ options, selectedOptions, onChange, isOpen, toggleDropdown }) => {
    const [filterSelected, setFilterSelected] = useState(false);

    const uniqueOptions = [...new Map(options.map(item => [item.id, item])).values()];

    const handleSelectAll = () => {
        const allOptionIds = uniqueOptions.map((option) => option.id);
        onChange(allOptionIds);
    };

    const handleClearAll = () => {
        onChange([]);
    };

    const handleOptionChange = (optionId) => {
        const isSelected = selectedOptions.includes(optionId);
        const updatedSelection = isSelected
            ? selectedOptions.filter((id) => id !== optionId)
            : [...selectedOptions, optionId];
        onChange(updatedSelection);
    };

    const displayedOptions = filterSelected
        ? uniqueOptions.filter((option) => selectedOptions.includes(option.id))
        : uniqueOptions;

    const dropdownHeaderText = selectedOptions.length > 0
        ? `${selectedOptions.length} selected`
        : "Select payout currencies";

    return (
        <DropdownContainer>
            <DropdownHeader onClick={toggleDropdown}>
                {dropdownHeaderText}
                <span>
                    {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                </span>
            </DropdownHeader>

            {isOpen && (
                <DropdownMenu>
                    <DropdownControls>
                        <ControlButtonsRow>
                            <ControlButton $primary onClick={handleSelectAll}>
                                Select all
                            </ControlButton>
                            <ControlButton onClick={handleClearAll}>
                                Clear all
                            </ControlButton>
                        </ControlButtonsRow>

                        <RadioGroup>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    checked={!filterSelected}
                                    onChange={() => setFilterSelected(false)}
                                />
                                Show all
                            </RadioLabel>
                            <RadioLabel>
                                <input
                                    type="radio"
                                    checked={filterSelected}
                                    onChange={() => setFilterSelected(true)}
                                />
                                Show only selected ({selectedOptions.length})
                            </RadioLabel>
                        </RadioGroup>
                    </DropdownControls>

                    <OptionsList>
                        {displayedOptions.map((option) => (
                            <OptionItem key={option.id}>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${option.id}`}
                                    checked={selectedOptions.includes(option.id)}
                                    onChange={() => handleOptionChange(option.id)}
                                />
                                <label htmlFor={`checkbox-${option.id}`}>
                                    {option.name}
                                </label>
                            </OptionItem>
                        ))}
                    </OptionsList>

                    <DoneButton onClick={toggleDropdown}>
                        Done
                    </DoneButton>
                </DropdownMenu>
            )}
        </DropdownContainer>
    );
};

export default MultiSelectDropdown;