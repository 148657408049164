import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedForms: {
        wolfsberg_banks: false,
        wolfsberg_non_banks: false,
        b2b_enhanced: false,
        reseller_enhanced: false,
        agent_enhanced: false
    },
    selectedDocuments: {
        power_of_attorney: false,
        articles_of_incorporation: false,
        business_registration: false,
        financial_license: false,
        photo_id: false
    },
    isSubmitting: false,
    error: null
};

export const requiredDocumentsSlice = createSlice({
    name: 'requiredDocuments',
    initialState,
    reducers: {
        updateSelectedForms: (state, action) => {
            state.selectedForms = {
                ...state.selectedForms,
                [action.payload.key]: action.payload.value
            };
        },
        updateSelectedDocuments: (state, action) => {
            state.selectedDocuments = {
                ...state.selectedDocuments,
                [action.payload.key]: action.payload.value
            };
        },
        setIsSubmitting: (state, action) => {
            state.isSubmitting = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        resetDocumentation: () => initialState
    }
});

// Action creators
export const {
    updateSelectedForms,
    updateSelectedDocuments,
    setIsSubmitting,
    setError,
    resetDocumentation
} = requiredDocumentsSlice.actions;

// Selectors
export const selectSelectedForms = (state) => state.requiredDocuments.selectedForms;
export const selectSelectedDocuments = (state) => state.requiredDocuments.selectedDocuments;
export const selectIsSubmitting = (state) => state.requiredDocuments.isSubmitting;
export const selectError = (state) => state.requiredDocuments.error;

export default requiredDocumentsSlice.reducer;