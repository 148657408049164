// components/ComplianceForms/OwnershipControl/OwnershipControlForm.js
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SectionDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
`;

const SubSection = styled(FormSection)`
  margin-top: 48px;
  padding-top: 32px;
  border-top: 1px solid #eee;
`;

const PersonSection = styled(FormSection)`
  margin-top: 32px;
  padding-top: 24px;
  &:not(:first-child) {
    border-top: 1px solid #eee;
  }
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 8px 0;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const AddButton = styled.button`
  color: #f26e22;
  background: none;
  border: none;
  padding: 0;
  margin-top: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

const OwnershipControlForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('ownership'));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateStepData({
            step: 'ownership',
            data: { [name]: value }
        }));
    };

    const handleOwnershipDetailsChange = (index, field, value) => {
        const newOwnershipDetails = [...(formData?.ownershipDetails || [])];
        newOwnershipDetails[index] = {
            ...newOwnershipDetails[index],
            [field]: value
        };
        dispatch(updateStepData({
            step: 'ownership',
            data: { ownershipDetails: newOwnershipDetails }
        }));
    };

    const handleControlDetailsChange = (index, field, value) => {
        const newControlDetails = [...(formData?.controlDetails || [])];
        newControlDetails[index] = {
            ...newControlDetails[index],
            [field]: value
        };
        dispatch(updateStepData({
            step: 'ownership',
            data: { controlDetails: newControlDetails }
        }));
    };

    const addOwnershipPerson = () => {
        const newPerson = {
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            nationality: '',
            ownershipPercentage: '',
            isPEP: 'no',
            isPEPConnected: 'no'
        };
        dispatch(updateStepData({
            step: 'ownership',
            data: {
                ownershipDetails: [...(formData?.ownershipDetails || []), newPerson]
            }
        }));
    };

    const addControlPerson = () => {
        const newPerson = {
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            nationality: '',
            titleRole: '',
            residenceLocation: '',
            isPEP: 'no',
            isPEPConnected: 'no'
        };
        dispatch(updateStepData({
            step: 'ownership',
            data: {
                controlDetails: [...(formData?.controlDetails || []), newPerson]
            }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>OWNERSHIP AND CONTROL</SectionTitle>
                <SectionDescription>
                    For non-exempt entities provide the Beneficial Ownership information below
                </SectionDescription>

                <FormSection>
                    <SectionTitle>Ownership</SectionTitle>
                    <SectionDescription>
                        Are there persons (individuals) who directly or indirectly own 20% or more of the company?
                    </SectionDescription>

                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOwnershipPersons"
                                value="no"
                                checked={formData?.hasOwnershipPersons === 'no'}
                                onChange={handleInputChange}
                            />
                            No, they're are no individuals who directly or indirectly own 20% or more of the company
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOwnershipPersons"
                                value="yes"
                                checked={formData?.hasOwnershipPersons === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes, they're are individuals who directly or indirectly own 20% or more of the company
                        </RadioLabel>
                    </RadioGroup>

                    {formData?.hasOwnershipPersons === 'yes' && (
                        <>
                            {formData?.ownershipDetails?.map((person, index) => (
                                <FormSection key={index}>
                                    <FormRow>
                                        <FormGroup>
                                            <Label>First name</Label>
                                            <Input
                                                value={person.firstName}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'firstName', e.target.value)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Last name</Label>
                                            <Input
                                                value={person.lastName}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'lastName', e.target.value)}
                                            />
                                        </FormGroup>
                                    </FormRow>

                                    <FormRow>
                                        <FormGroup>
                                            <Label>Date of birth</Label>
                                            <Input
                                                type="date"
                                                value={person.dateOfBirth}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'dateOfBirth', e.target.value)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Nationality</Label>
                                            <Input
                                                value={person.nationality}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'nationality', e.target.value)}
                                            />
                                        </FormGroup>
                                    </FormRow>

                                    <FormRow>
                                        <FormGroup>
                                            <Label>Percentage ownership of shares or voting rights</Label>
                                            <Input
                                                value={person.ownershipPercentage}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'ownershipPercentage', e.target.value)}
                                                placeholder="XX %"
                                            />
                                        </FormGroup>
                                    </FormRow>

                                    <RadioGroup>
                                        <Label>Is this individual a politically exposed person (PEP)?</Label>
                                        <RadioLabel>
                                            <input
                                                type="radio"
                                                name={`ownership_pep_${index}`}
                                                value="no"
                                                checked={person.isPEP === 'no'}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'isPEP', e.target.value)}
                                            />
                                            No, they're not a PEP
                                        </RadioLabel>
                                        <RadioLabel>
                                            <input
                                                type="radio"
                                                name={`ownership_pep_${index}`}
                                                value="yes"
                                                checked={person.isPEP === 'yes'}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'isPEP', e.target.value)}
                                            />
                                            Yes, they're a PEP
                                        </RadioLabel>
                                    </RadioGroup>

                                    <RadioGroup>
                                        <Label>Is this individual connected to a PEP?</Label>
                                        <RadioLabel>
                                            <input
                                                type="radio"
                                                name={`ownership_pep_connected_${index}`}
                                                value="no"
                                                checked={person.isPEPConnected === 'no'}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'isPEPConnected', e.target.value)}
                                            />
                                            No, they're not connected to a PEP
                                        </RadioLabel>
                                        <RadioLabel>
                                            <input
                                                type="radio"
                                                name={`ownership_pep_connected_${index}`}
                                                value="yes"
                                                checked={person.isPEPConnected === 'yes'}
                                                onChange={(e) => handleOwnershipDetailsChange(index, 'isPEPConnected', e.target.value)}
                                            />
                                            Yes, they're connected to a PEP
                                        </RadioLabel>
                                    </RadioGroup>
                                </FormSection>
                            ))}
                            <AddButton onClick={addOwnershipPerson}>
                                + Add another person with direct or indirect company ownership
                            </AddButton>
                        </>
                    )}
                </FormSection>

                {/* Control Section */}
                <SubSection>
                    <SectionTitle>Control</SectionTitle>
                    <SectionDescription>
                        Details of individuals with significant responsibility to control, manage, or authorize (signing authority) the financial resources of the customer (e.g. Board of Directors and applicable C-level officers, such as CEO, COO, CFO, etc)
                    </SectionDescription>

                    {formData?.controlDetails?.map((person, index) => (
                        <PersonSection key={index}>
                            <FormRow>
                                <FormGroup>
                                    <Label>First name</Label>
                                    <Input
                                        value={person.firstName}
                                        onChange={(e) => handleControlDetailsChange(index, 'firstName', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Last name</Label>
                                    <Input
                                        value={person.lastName}
                                        onChange={(e) => handleControlDetailsChange(index, 'lastName', e.target.value)}
                                    />
                                </FormGroup>
                            </FormRow>

                            <FormRow>
                                <FormGroup>
                                    <Label>Date of birth</Label>
                                    <Input
                                        type="date"
                                        value={person.dateOfBirth}
                                        onChange={(e) => handleControlDetailsChange(index, 'dateOfBirth', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Nationality</Label>
                                    <Input
                                        value={person.nationality}
                                        onChange={(e) => handleControlDetailsChange(index, 'nationality', e.target.value)}
                                    />
                                </FormGroup>
                            </FormRow>

                            <FormRow>
                                <FormGroup>
                                    <Label>Title/Role</Label>
                                    <Input
                                        value={person.titleRole}
                                        onChange={(e) => handleControlDetailsChange(index, 'titleRole', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Country and city of residence</Label>
                                    <Input
                                        value={person.residenceLocation}
                                        onChange={(e) => handleControlDetailsChange(index, 'residenceLocation', e.target.value)}
                                    />
                                </FormGroup>
                            </FormRow>

                            <RadioGroup>
                                <Label>Is this individual a politically exposed person (PEP)?</Label>
                                <RadioLabel>
                                    <input
                                        type="radio"
                                        name={`control_pep_${index}`}
                                        value="no"
                                        checked={person.isPEP === 'no'}
                                        onChange={(e) => handleControlDetailsChange(index, 'isPEP', e.target.value)}
                                    />
                                    No, they're not a PEP
                                </RadioLabel>
                                <RadioLabel>
                                    <input
                                        type="radio"
                                        name={`control_pep_${index}`}
                                        value="yes"
                                        checked={person.isPEP === 'yes'}
                                        onChange={(e) => handleControlDetailsChange(index, 'isPEP', e.target.value)}
                                    />
                                    Yes, they're a PEP
                                </RadioLabel>
                            </RadioGroup>

                            <RadioGroup>
                                <Label>Is this individual connected to a PEP?</Label>
                                <RadioLabel>
                                    <input
                                        type="radio"
                                        name={`control_pep_connected_${index}`}
                                        value="no"
                                        checked={person.isPEPConnected === 'no'}
                                        onChange={(e) => handleControlDetailsChange(index, 'isPEPConnected', e.target.value)}
                                    />
                                    No, they're not connected to a PEP
                                </RadioLabel>
                                <RadioLabel>
                                    <input
                                        type="radio"
                                        name={`control_pep_connected_${index}`}
                                        value="yes"
                                        checked={person.isPEPConnected === 'yes'}
                                        onChange={(e) => handleControlDetailsChange(index, 'isPEPConnected', e.target.value)}
                                    />
                                    Yes, they're connected to a PEP
                                </RadioLabel>
                            </RadioGroup>
                        </PersonSection>
                    ))}
                    <AddButton onClick={addControlPerson}>
                        + Add another person with control or authorization
                    </AddButton>
                </SubSection>
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default OwnershipControlForm;