export const WOLFSBERG_STEPS = [
    {
        id: 'entity',
        label: 'Entity and ownership'
    },
    {
        id: 'amlctf',
        label: 'AML,CTF and sanctions program'
    },
    {
        id: 'antibribery',
        label: 'Anti bribery and corruption'
    },
    {
        id: 'amlpolicies',
        label: 'AML,CTF and sanctions policies'
    },
    {
        id: 'kyc',
        label: 'KYC, CDD and EDD'
    },
    {
        id: 'monitoring',
        label: 'Monitoring and reporting'
    },
    {
        id: 'payment',
        label: 'Payment transparency'
    },
    {
        id: 'sanctions',
        label: 'Sanctions'
    },
    {
        id: 'training',
        label: 'Training and education'
    },
    {
        id: 'audit',
        label: 'Audit'
    }
];

export const getStepIndex = (stepId) => {
    return WOLFSBERG_STEPS.findIndex(step => step.id === stepId);
};

export const getNextStep = (currentStepId) => {
    const currentIndex = getStepIndex(currentStepId);
    return currentIndex < WOLFSBERG_STEPS.length - 1
        ? WOLFSBERG_STEPS[currentIndex + 1].id
        : null;
};

export const getPreviousStep = (currentStepId) => {
    const currentIndex = getStepIndex(currentStepId);
    return currentIndex > 0
        ? WOLFSBERG_STEPS[currentIndex - 1].id
        : null;
};
