import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentStep: 'customer',
    completedSteps: [],
    steps: {
        customer: {
            completed: false,
            data: {
                businessName: '',
                registerNumber: '',
                dateOfRegistration: '',
                businessCountry: '',
                tradingEmail: '',
                isListed: 'no',
                stockName: '',
                stockCode: '',
                isRegulated: 'no',
                regulatorName: '',
                hasAdverseMedia: 'no',
                mediaDetails: '',
                hasRegulatoryFines: 'no',
                website: '',
                bankId: '',
                bankIdType: '',
                masterIca: '',
                customerId: ''
            }
        },
        ownership: {
            completed: false,
            data: {
                hasOwnershipPersons: 'no',
                ownershipDetails: [],
                controlDetails: [{
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    nationality: '',
                    titleRole: '',
                    residenceLocation: '',
                    isPEP: 'no',
                    isPEPConnected: 'no'
                }]
            }
        },
        funding: {
            completed: false,
            data: {
                bankName: '',
                accountHolder: '',
                swiftCode: '',
                fundingCurrency: '',
                dailyCreditLimit: ''
            }
        },
        declaration: {
            completed: false,
            data: {
                businessOverview: '',
                paymentType: '',
                activities: '',
                screeningFrequency: '',
                screeningExplanation: '',
                screeningScope: '',
                fundsSource: '',
                paymentMethod: '',
                accountType: '',
                isReseller: 'no',
                usesAgents: 'no',
                hasOffshoreClients: 'no',
                licenseDescription: '',
                complianceConfirmation: '',
                jurisdictionList: '',
                amlPolicies: ''
            }
        },
        contacts: {
            completed: false,
            data: {
                contacts: [{
                    firstName: '',
                    lastName: '',
                    designation: '',
                    department: '',
                    phoneNumber: '',
                    emailAddress: ''
                }]
            }
        },
        acknowledgement: {
            completed: false,
            data: {
                legalName: '',
                titleRole: '',
                date: '',
                legalConfirmation: false,
                isCorrect: '',
                comments: ''
            }
        }
    },
    isFormDirty: false,
    lastSaved: null
};

const complianceSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        completeStep: (state, action) => {
            const stepId = action.payload;
            if (!state.completedSteps.includes(stepId)) {
                state.completedSteps.push(stepId);
                state.steps[stepId].completed = true;
            }
        },
        updateStepData: (state, action) => {
            const { step, data } = action.payload;
            state.steps[step].data = {
                ...state.steps[step].data,
                ...data
            };
            state.isFormDirty = true;
        },
        setFormDirty: (state, action) => {
            state.isFormDirty = action.payload;
        },
        saveProgress: (state) => {
            state.lastSaved = new Date().toISOString();
            state.isFormDirty = false;
        },
        resetForm: () => initialState
    }
});

// Action creators
export const {
    setCurrentStep,
    completeStep,
    updateStepData,
    setFormDirty,
    saveProgress,
    resetForm
} = complianceSlice.actions;

// Selectors
export const selectCompliance = (state) => state.compliance;
export const selectCurrentStep = (state) => state.compliance.currentStep;
export const selectCompletedSteps = (state) => state.compliance.completedSteps;
export const selectStepData = (stepId) => (state) => state.compliance.steps[stepId]?.data;
export const selectIsStepCompleted = (stepId) => (state) => state.compliance.steps[stepId].completed;
export const selectIsFormDirty = (state) => state.compliance.isFormDirty;
export const selectLastSaved = (state) => state.compliance.lastSaved;
export const selectAllStepsData = (state) => {
    const { steps } = state.compliance;
    return Object.keys(steps).reduce((acc, stepId) => {
        acc[stepId] = steps[stepId].data;
        return acc;
    }, {});
};

export default complianceSlice.reducer;