import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 400px;
  max-width: 90%;
`;

const Title = styled.h2`
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  margin: 20px 0;
  color: #666;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
`;

const ConfirmButton = styled(Button)`
  background-color: #f26e22;
  color: white;
  border: none;
  &:hover {
    background-color: #e85f0c;
  }
`;

const CancelButton = styled(Button)`
  background-color: white;
  color: #f26e22;
  border: 2px solid #f26e22;
  &:hover {
    background-color: #fff5f0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
  }
`;

const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <>
            <ModalOverlay onClick={onCancel} />
            <ModalContainer>
                <CloseButton onClick={onCancel}>×</CloseButton>
                <Title>Confirm Action</Title>
                <Message>{message}</Message>
                <ButtonContainer>
                    <ConfirmButton onClick={onConfirm}>
                        Confirm
                    </ConfirmButton>
                    <CancelButton onClick={onCancel}>
                        Cancel
                    </CancelButton>
                </ButtonContainer>
            </ModalContainer>
        </>
    );
};

export default ConfirmationModal;