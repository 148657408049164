import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/wolfsbergSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
  text-transform: uppercase;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
column-gap: 40px; `;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 8px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;  // Make sure width is 100%
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;  // Add consistent height
  box-sizing: border-box;  // Important - includes padding in width calculation
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const Select = styled.select`
  width: 100%; 
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  height: 40px; 
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const ConditionalSection = styled.div`
  margin-top: 16px;
  margin-left: 24px;
  padding-left: 16px;
`;

const EntityOwnershipForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('entity'));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateStepData({
            step: 'entity',
            data: { [name]: value }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>ENTITY AND OWNERSHIP</SectionTitle>

                <FormRow>
                    <FormGroup>
                        <Label>Financial institution name</Label>
                        <Input
                            name="financialInstitutionName"
                            value={formData?.financialInstitutionName || ''}
                            onChange={handleInputChange}
                            placeholder="Placeholder text"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Location (Country)</Label>
                        <Input
                            name="location"
                            value={formData?.location || ''}
                            onChange={handleInputChange}
                            placeholder="Placeholder text"
                        />
                    </FormGroup>
                </FormRow>

                <FormGroup>
                    <Label>Full legal name</Label>
                    <Input
                        name="fullLegalName"
                        value={formData?.fullLegalName || ''}
                        onChange={handleInputChange}
                        placeholder="Placeholder text"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Append a list of foreign branches which are covered by this questionnaire (if applicable)</Label>
                    <TextArea
                        name="foreignBranches"
                        value={formData?.foreignBranches || ''}
                        onChange={handleInputChange}
                        placeholder="Placeholder"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Full legal (registered) address</Label>
                    <Input
                        name="legalAddress"
                        value={formData?.legalAddress || ''}
                        onChange={handleInputChange}
                        placeholder="Placeholder text"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Full primary business address (if different from above)</Label>
                    <Input
                        name="businessAddress"
                        value={formData?.businessAddress || ''}
                        onChange={handleInputChange}
                        placeholder="Placeholder text"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Date of entity incorporation/establishment</Label>
                    <Input
                        type="date"
                        name="dateOfIncorporation"
                        value={formData?.dateOfIncorporation || ''}
                        onChange={handleInputChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Select type of ownership and append an ownership chart if available</Label>
                    <Select
                        name="ownershipType"
                        value={formData?.ownershipType || ''}
                        onChange={handleInputChange}
                    >
                        <option value="">Select an option</option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="government">Government</option>
                    </Select>
                </FormGroup>

                <FormGroup>
                    <Label>Does the business been traded publicly? (25% of shares publicly traded)</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isPubliclyTraded"
                                value="no"
                                checked={formData?.isPubliclyTraded === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isPubliclyTraded"
                                value="yes"
                                checked={formData?.isPubliclyTraded === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>

                    {formData?.isPubliclyTraded === 'yes' && (
                        <ConditionalSection>
                            <FormGroup>
                                <Label>The exchange traded on and ticker symbol</Label>
                                <Input
                                    name="exchangeInfo"
                                    value={formData?.exchangeInfo || ''}
                                    onChange={handleInputChange}
                                    placeholder="Placeholder"
                                />
                            </FormGroup>
                        </ConditionalSection>
                    )}
                </FormGroup>

                <FormGroup>
                    <Label>Does the business member owned/mutual?</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isMemberOwned"
                                value="no"
                                checked={formData?.isMemberOwned === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isMemberOwned"
                                value="yes"
                                checked={formData?.isMemberOwned === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>
                </FormGroup>

                <FormGroup>
                    <Label>Does the business government or state owned by 25% or more?</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isGovernmentOwned"
                                value="no"
                                checked={formData?.isGovernmentOwned === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isGovernmentOwned"
                                value="yes"
                                checked={formData?.isGovernmentOwned === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>
                </FormGroup>

                <FormGroup>
                    <Label>Does the business privately owned?</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isPrivatelyOwned"
                                value="no"
                                checked={formData?.isPrivatelyOwned === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isPrivatelyOwned"
                                value="yes"
                                checked={formData?.isPrivatelyOwned === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>

                    {formData?.isPrivatelyOwned === 'yes' && (
                        <ConditionalSection>
                            <FormGroup>
                                <Label>Provide details of shareholders or ultimate beneficial owners with a holding of 10% or more</Label>
                                <TextArea
                                    name="shareholderDetails"
                                    value={formData?.shareholderDetails || ''}
                                    onChange={handleInputChange}
                                    placeholder="Placeholder"
                                />
                            </FormGroup>
                        </ConditionalSection>
                    )}
                </FormGroup>

                <FormGroup>
                    <Label>Percentage of the entity's total shares composed of bearer share</Label>
                    <Select
                        name="bearerSharePercentage"
                        value={formData?.bearerSharePercentage || ''}
                        onChange={handleInputChange}
                    >
                        <option value="">Select an option</option>
                        <option value="0">0%</option>
                        <option value="1-10">1-10%</option>
                        <option value="11-25">11-25%</option>
                        <option value="26-50">26-50%</option>
                        <option value="51-100">51-100%</option>
                    </Select>
                </FormGroup>

                <FormGroup>
                    <Label>Does the entity, or any of its branches, operate under an offshore banking license (OBL)?</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOffshoreBranches"
                                value="no"
                                checked={formData?.hasOffshoreBranches === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOffshoreBranches"
                                value="yes"
                                checked={formData?.hasOffshoreBranches === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>

                    {formData?.hasOffshoreBranches === 'yes' && (
                        <ConditionalSection>
                            <FormGroup>
                                <Label>Provide the name of the relevant branch/es which operate under an OBL</Label>
                                <TextArea
                                    name="branchDetails"
                                    value={formData?.branchDetails || ''}
                                    onChange={handleInputChange}
                                    placeholder="Placeholder"
                                />
                            </FormGroup>
                        </ConditionalSection>
                    )}
                </FormGroup>

                <FormGroup>
                    <Label>Does the bank have a virtual bank license or provide services only through online channels?</Label>
                    <RadioGroup>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasVirtualBank"
                                value="no"
                                checked={formData?.hasVirtualBank === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasVirtualBank"
                                value="yes"
                                checked={formData?.hasVirtualBank === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>
                </FormGroup>

                <FormGroup>
                    <Label>Provide legal entity identifier (LEI) if available</Label>
                    <Input
                        name="legalEntityIdentifier"
                        value={formData?.legalEntityIdentifier || ''}
                        onChange={handleInputChange}
                        placeholder="Placeholder text"
                    />
                </FormGroup>
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default EntityOwnershipForm;