import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PaymentDestination from "./pages/paymentDestination/paymentDestination";
import styled from 'styled-components';
import UploadDocument from "./pages/uploadDocument/uploadDocument";
import Compliance from "./pages/compliance/Compliance";
import { Provider } from 'react-redux';
import { store } from './store/store';
import RequiredDocumentation from "./pages/requiredDocuments/RequiredDocumentation";
import Wolfsberg from "./pages/wolfsberg/WolfsbergForm";

const AppContainer = styled.div`
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
`;

const App = () => {
  return (
    <Router>
      <AppContainer>
        <Provider store={store}>
          <Switch>
            <Route exact path="/" component={PaymentDestination} />
            <Route path="/upload-document" component={UploadDocument} />
            <Route path="/compliance" component={Compliance} />
            <Route path="/required-documents" component={RequiredDocumentation} />
            <Route path="/wolfsberg" component={Wolfsberg} />
            <Route path="*" component={() => <h1>404 Not Found</h1>} />
          </Switch>
        </Provider>
      </AppContainer>
    </Router>
  );
};

export default App;

//protected routes
// import React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import PaymentDestination from "./pages/paymentDestination/paymentDestination";
// import styled from 'styled-components';
// import UploadDocument from "./pages/uploadDocument/uploadDocument";
// import Compliance from "./pages/compliance/Compliance";
// import { Provider } from 'react-redux';
// import { store } from './store/store';
// import ProtectedRoute from './routes/ProtectedRoute';

// const AppContainer = styled.div`
//   font-family: 'Arial', sans-serif;
//   margin: 0;
//   padding: 0;
//   background-color: #f9f9f9;
//   color: #333;
// `;

// const App = () => {
//   return (
//     <Router>
//       <AppContainer>
//         <Provider store={store}>
//           <Switch>
//             {/* Public route */}
//             <Route exact path="/" component={PaymentDestination} />

//             {/* Protected routes */}
//             <ProtectedRoute path="/upload-document" component={UploadDocument} />
//             <ProtectedRoute path="/compliance" component={Compliance} />

//             {/* 404 route */}
//             <Route path="*" component={() => <h1>404 Not Found</h1>} />
//           </Switch>
//         </Provider>
//       </AppContainer>
//     </Router>
//   );
// };

// export default App;