import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #333;
  text-transform: uppercase;
`;

const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoIcon = styled.span`
  cursor: help;
  color: #666;
  font-size: 16px;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const CustomerFundingForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('funding'));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateStepData({
            step: 'funding',
            data: { [name]: value }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>CUSTOMER FUNDING</SectionTitle>
                <SubTitle>Customer's funding information</SubTitle>

                <FormRow>
                    <FormGroup>
                        <Label>Customer's bank name</Label>
                        <Input
                            name="bankName"
                            value={formData?.bankName || ''}
                            onChange={handleInputChange}
                            placeholder="Bank of Awesomeness"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Customer's account name (account holder)</Label>
                        <Input
                            name="accountHolder"
                            value={formData?.accountHolder || ''}
                            onChange={handleInputChange}
                            placeholder="XXXXXXX"
                        />
                    </FormGroup>
                </FormRow>

                <FormRow>
                    <FormGroup>
                        <Label>SWIFT code</Label>
                        <Input
                            name="swiftCode"
                            value={formData?.swiftCode || ''}
                            onChange={handleInputChange}
                            placeholder="XXXXXXXX"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Funding currency</Label>
                        <Input
                            name="fundingCurrency"
                            value={formData?.fundingCurrency || ''}
                            onChange={handleInputChange}
                            placeholder="USD"
                        />
                    </FormGroup>
                </FormRow>

                <FormRow>
                    <FormGroup>
                        <Label>
                            Requested daily credit limit
                            <InfoIcon title="Enter the requested daily credit limit">ℹ️</InfoIcon>
                        </Label>
                        <Input
                            name="dailyCreditLimit"
                            value={formData?.dailyCreditLimit || ''}
                            onChange={handleInputChange}
                            placeholder="X,XXX.XX"
                        />
                    </FormGroup>
                </FormRow>
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default CustomerFundingForm;