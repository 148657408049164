import axios from "axios";
import { store } from '../store/store';

//Backend API URL
const API_BASE_URL = "https://mc.clickthrough.compliance.mooestroportal.com/customer-compliance-onboarding-1.0.0/api";

//create a random request id if not already created
let sharedRequestId = null;

const getOrCreateRequestId = () => {
    if (!sharedRequestId) {
        sharedRequestId = crypto.randomUUID();
    }
    return sharedRequestId;
};

export const resetRequestId = () => {
    sharedRequestId = null;
};

// Fetch data from the API with 3 retries if first two attempts fail
const fetchWithRetry = async (url, options, retries = 3) => {
    try {
        const response = await axios(url, options);
        return response;
    } catch (error) {
        if (retries > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            return fetchWithRetry(url, options, retries - 1);
        }
        throw error;
    }
};

// Fetch countries from the API
export const fetchCountries = async () => {
    try {
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/countries`, {
            method: 'GET',
            headers: {
                'request-id': getOrCreateRequestId()
            }
        });
        return response.data.data.list.map(country => ({
            countryName: country.countryName,
            countryCode: country.countryCode,
        }));
    } catch (error) {
        console.error("Error fetching countries:", error);
        return [];
    }
};

// Fetch services by country from the API
export const fetchServicesByCountry = async (countryName) => {
    try {
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/by-country?countryName=${countryName}`, {
            method: 'GET',
            headers: {
                'request-id': getOrCreateRequestId()
            }
        });
        const services = response.data.data.list;

        return services.map(service => ({
            serviceName: service.serviceName,
            currencies: [...new Set(service.details.map(detail => ({
                id: detail.payoutCurrency,
                name: detail.payoutCurrency,
            })))],
            paymentTypes: [...new Set(service.details.map(detail => ({
                id: detail.paymentType,
                name: detail.paymentType,
            })))]
        }));
    } catch (error) {
        console.error("Error fetching services:", error);
        return [];
    }
};

// Save destination data to the API in AddDestinatioModal
export const saveDestination = async (formData) => {
    try {
        // First fetch existing destinations
        const existingDestinations = await fetchDestinations();

        // Prepare the new destination data
        const newDestination = {
            countryName: formData.country,
            services: formData.services.map(service => ({
                serviceName: service.name,
                payoutCurrency: Array.isArray(service.payoutOptions.currencies)
                    ? service.payoutOptions.currencies[0]
                    : service.payoutOptions.currencies,
                paymentType: Array.isArray(service.payoutOptions.paymentTypes)
                    ? service.payoutOptions.paymentTypes.join(", ")
                    : service.payoutOptions.paymentTypes
            }))
        };

        // Transform existing destinations to match the expected format
        const transformedExistingDestinations = existingDestinations.map(dest => ({
            countryName: dest.country,
            services: dest.services.map(service => ({
                serviceName: service.service,
                payoutCurrency: service.payoutCurrency,
                paymentType: service.payoutType
            }))
        }));

        // Combine existing and new destinations
        const allDestinations = [...transformedExistingDestinations, newDestination];

        // Make POST request with all destinations
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/save-for-later`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId(),
                'country': formData.country
            },
            data: allDestinations
        });

        return response.data;
    } catch (error) {
        console.error("Error saving destination:", error);
        throw error;
    }
};

// Fetch destinations from the API for grid display
export const fetchDestinations = async () => {
    try {
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/corridors`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId(),
            }
        });

        // Get the originating country from Redux store
        const originatingCountry = store.getState().destinations.originatingCountry;

        // Process and group the data
        const groupedData = response.data.data.reduce((acc, item) => {
            if (!acc[item.countryName]) {
                acc[item.countryName] = {
                    country: item.countryName,
                    originatingCountry: originatingCountry, // Use Redux value
                    services: []
                };
            }

            acc[item.countryName].services.push({
                service: item.serviceName,
                payoutCurrency: item.currency,
                payoutType: item.paymentType
            });

            return acc;
        }, {});

        // Convert the grouped data to array format
        const destinations = Object.values(groupedData);

        return destinations;
    } catch (error) {
        console.error("Error fetching destinations:", error);
        throw error;
    }
};

// Update destination data in the API
export const updateDestination = async (countryData) => {
    try {
        // First fetch all existing destinations
        const existingDestinations = await fetchDestinations();

        // Transform existing destinations, excluding the country being updated
        const otherDestinations = existingDestinations
            .filter(dest => dest.country !== countryData.country)
            .map(dest => ({
                countryName: dest.country,
                services: dest.services.map(service => ({
                    serviceName: service.service,
                    payoutCurrency: Array.isArray(service.payoutCurrency)
                        ? service.payoutCurrency[0]
                        : service.payoutCurrency,
                    paymentType: Array.isArray(service.payoutType)
                        ? service.payoutType.join(", ")
                        : typeof service.payoutType === 'string'
                            ? service.payoutType
                            : ''
                }))
            }));

        // Format the updated country data
        const updatedDestination = {
            countryName: countryData.country,
            services: countryData.services.map(service => ({
                serviceName: service.name,
                payoutCurrency: Array.isArray(service.payoutOptions.currencies)
                    ? service.payoutOptions.currencies[0]
                    : service.payoutOptions.currencies,
                paymentType: Array.isArray(service.payoutOptions.paymentTypes)
                    ? service.payoutOptions.paymentTypes.join(", ")
                    : service.payoutOptions.paymentTypes
            }))
        };

        // Combine all destinations - keep existing ones and add the updated one
        const formattedData = [
            ...otherDestinations,
            updatedDestination
        ];

        // Make sure we're sending all destinations in the PATCH request
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/corridors`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId(),
                'country': countryData.country
            },
            data: formattedData // Send all destinations
        });

        // Return full updated list
        return response.data;
    } catch (error) {
        console.error("Error updating destination:", error);
        throw error;
    }
};

// Delete destination data from the API
export const deleteDestination = async (country) => {
    try {
        await fetchWithRetry(`${API_BASE_URL}/service-details/corridors`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId(),
                'country': country
            }
        });
    } catch (error) {
        throw error;
    }
};

//Save dessiantions on save and continue
export const saveDestinationAndContinue = async (data) => {
    const formattedData = data.map(item => ({
        countryName: item.country,
        services: item.services.map(service => ({
            serviceName: service.service,
            payoutCurrency: service.payoutCurrency,
            paymentType: service.payoutType
        }))
    }));

    try {
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId()
            },
            data: formattedData
        });
        resetRequestId();
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Save destination data for later
export const saveDestinationForLater = async (data) => {
    try {
        const response = await fetchWithRetry(`${API_BASE_URL}/service-details/save-for-later`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'request-id': getOrCreateRequestId()
            },
            data: data
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

//Upload document to the API with json format
export const uploadDocument = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetchWithRetry(
            `${API_BASE_URL}/service/upload`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
                data: formData
            }
        );
        return response.data; // Return the parsed JSON response
    } catch (error) {
        console.error('Upload error:', error.response?.data || error.message);
        throw new Error(error.response?.data?.message || 'File upload failed');
    }
};