import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ComplianceHeader from '../../components/common/ComplianceHeader/ComplianceHeader';
import ProgressSteps from '../../components/common/ProgressSteps/ProgressSteps';
import { getNextStep } from '../../components/common/ProgressSteps/ComplianceSteps';
import {
    selectCurrentStep,
    setCurrentStep,
    completeStep,
    selectAllStepsData,
    saveProgress
} from '../../store/complianceSlice';
import CustomerDetailsForm from './CustomerDetailsForm';
import OwnershipControlForm from './OwnershipControlForm';
import CustomerFundingForm from './CustomerFundingForm';
import DeclarationBusinessForm from './DeclarationBusinessForm';
import CustomerContactsForm from './CustomerContactsForm';
import CustomerAcknowledgementForm from './CustomerAcknowledgementForm';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 32px;
  margin-bottom: 80px;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  position: relative;
`;

const Compliance = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentStep = useSelector(selectCurrentStep);

    const currentStepData = useSelector((state) =>
        state.compliance.steps[currentStep]?.data || {}
    );

    const stepsData = useSelector(selectAllStepsData);


    const handleStepClick = (stepId) => {
        dispatch(setCurrentStep(stepId));
    };

    const handleSaveAndContinue = async () => {
        try {
            // Save current step data
            console.log(`Saving ${currentStep} data:`, stepsData);

            // You can add API call here to save data
            // await saveStepDataToAPI(currentStep, currentStepData);

            // Mark step as complete
            dispatch(completeStep(currentStep));
            dispatch(saveProgress());

            // Move to next step
            const nextStep = getNextStep(currentStep);
            if (nextStep) {
                dispatch(setCurrentStep(nextStep));
            } else {
                // If it's the last step, you might want to redirect
                console.log('Compliance process completed!');
                // history.push('/completion');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            // Add error handling (e.g., show toast message)
        }
    };

    const handleSaveForLater = async () => {
        try {
            // Save current progress
            console.log(`Saving ${currentStep} data for later:`, currentStepData);

            // You can add API call here
            // await saveProgressForLater(currentStep, currentStepData);

            dispatch(saveProgress());

            // Store in localStorage for persistence
            localStorage.setItem('complianceLastStep', currentStep);
            localStorage.setItem(`complianceData_${currentStep}`, JSON.stringify(currentStepData));

            // Redirect to dashboard or relevant page
            history.push('/dashboard');
        } catch (error) {
            console.error('Error saving for later:', error);
            // Add error handling
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const renderFormContent = () => {
        const commonProps = {
            onSaveContinue: handleSaveAndContinue,
            onSaveLater: handleSaveForLater,
            onCancel: handleCancel
        };

        switch (currentStep) {
            case 'customer':
                return <CustomerDetailsForm {...commonProps} />;
            case 'ownership':
                return <OwnershipControlForm {...commonProps} />;
            case 'funding':
                return <CustomerFundingForm {...commonProps} />;
            case 'declaration':
                return <DeclarationBusinessForm {...commonProps} />;
            case 'contacts':
                return <CustomerContactsForm {...commonProps} />;
            case 'acknowledgement':
                return <CustomerAcknowledgementForm {...commonProps} />;
            // Add other form components here
            default:
                return null;
        }
    };

    return (
        <PageContainer>
            <ComplianceHeader
                title="Compliance and Due Diligence application"
                description="Brief descriptions of forms goes here..."
            />
            <ContentWrapper>
                <ProgressSteps type="compliance" onStepClick={handleStepClick} />
                <MainContent>
                    {renderFormContent()}
                </MainContent>
            </ContentWrapper>

        </PageContainer>
    );
};

export default Compliance;