import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const BaseButton = styled.button`
  padding: 10px 24px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
`;

const PrimaryButton = styled(BaseButton)`
  background-color: #f26e22;
  color: white;
  border: none;
`;

const SecondaryButton = styled(BaseButton)`
  background-color: white;
  border: 2px solid #f26e22;
  color: #f26e22;
`;

const TextButton = styled(BaseButton)`
  background: none;
  border: none;
  color: #f26e22;
`;

const ActionButtons = ({
  onSaveContinue,
  onSaveLater,
  onCancel,
  disabled,
  continueText = 'Save and continue',
  saveLaterText = 'Save for later',
  cancelText = 'Cancel'
}) => {
  return (
    <ButtonContainer>
      <PrimaryButton onClick={onSaveContinue} disabled={disabled}>
        {disabled ? 'Saving...' : continueText}
      </PrimaryButton>
      <SecondaryButton onClick={onSaveLater} disabled={disabled}>
        {disabled ? 'Saving...' : saveLaterText}
      </SecondaryButton>
      <TextButton onClick={onCancel} disabled={disabled}>
        {cancelText}
      </TextButton>
    </ButtonContainer>
  );
};

export default ActionButtons;