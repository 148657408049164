import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #333;
  text-transform: uppercase;
`;

const ContactSection = styled.div`
  margin-bottom: 32px;
  &:not(:last-child) {
    padding-bottom: 32px;
    border-bottom: 1px solid #eee;
  }
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const AddButton = styled.button`
  color: #f26e22;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: '+';
    font-size: 16px;
    font-weight: bold;
  }
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid #eee;
  margin: 32px 0;
`;

const CustomerContactsForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('contacts'));

    const handleContactChange = (index, field, value) => {
        const newContacts = [...(formData?.contacts || [])];
        newContacts[index] = {
            ...newContacts[index],
            [field]: value
        };
        dispatch(updateStepData({
            step: 'contacts',
            data: { contacts: newContacts }
        }));
    };

    const addContact = () => {
        const newContact = {
            firstName: '',
            lastName: '',
            designation: '',
            department: '',
            phoneNumber: '',
            emailAddress: ''
        };
        dispatch(updateStepData({
            step: 'contacts',
            data: {
                contacts: [...(formData?.contacts || []), newContact]
            }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>CUSTOMER CONTACTS</SectionTitle>

                {(formData?.contacts || []).map((contact, index) => (
                    <ContactSection key={index}>
                        <FormRow>
                            <FormGroup>
                                <Label>First name</Label>
                                <Input
                                    value={contact.firstName}
                                    onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                                    placeholder="Enter first name"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Last name</Label>
                                <Input
                                    value={contact.lastName}
                                    onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                                    placeholder="Enter last name"
                                />
                            </FormGroup>
                        </FormRow>

                        <FormRow>
                            <FormGroup>
                                <Label>Designation</Label>
                                <Input
                                    value={contact.designation}
                                    onChange={(e) => handleContactChange(index, 'designation', e.target.value)}
                                    placeholder="XXXXXXXX"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Department</Label>
                                <Input
                                    value={contact.department}
                                    onChange={(e) => handleContactChange(index, 'department', e.target.value)}
                                    placeholder="Enter department"
                                />
                            </FormGroup>
                        </FormRow>

                        <FormRow>
                            <FormGroup>
                                <Label>Phone number</Label>
                                <Input
                                    value={contact.phoneNumber}
                                    onChange={(e) => handleContactChange(index, 'phoneNumber', e.target.value)}
                                    placeholder="555-123-4567"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email address</Label>
                                <Input
                                    type="email"
                                    value={contact.emailAddress}
                                    onChange={(e) => handleContactChange(index, 'emailAddress', e.target.value)}
                                    placeholder="email@company.com"
                                />
                            </FormGroup>
                        </FormRow>
                    </ContactSection>
                ))}

                <AddButton onClick={addContact}>Add another contact</AddButton>

            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default CustomerContactsForm;