import { configureStore } from '@reduxjs/toolkit';
import destinationReducer from './destinationSlice';
import complianceReducer from './complianceSlice';
import requiredDocumentsReducer from './requiredDocumentsSlice';
import wolfsbergReducer from './wolfsbergSlice';

export const store = configureStore({
    reducer: {
        destinations: destinationReducer,
        compliance: complianceReducer,
        requiredDocuments: requiredDocumentsReducer,
        wolfsberg: wolfsbergReducer
    }
});