import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/wolfsbergSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #333;
  text-transform: uppercase;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 32px;
`;

const ProgramTable = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
`;

const HeaderItem = styled.div`
  font-weight: 500;
  text-align: ${props => props.align || 'left'};
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
  
  &:last-child {
    border-bottom: none;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 32px;
  justify-content: ${props => props.align === 'center' ? 'center' : 'flex-start'};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  margin-top: 16px;
  resize: vertical;
  box-sizing: border-box;

  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }

  &::placeholder {
    color: #999;
  }
`;

const ConditionalSection = styled.div`
  margin-top: 16px;
  margin-left: 24px;
`;

const AMLCTFSanctionPrograms = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('amlctf')) || {};

    const programComponents = [
        'Appointed officer with sufficient',
        'Adverse information screening',
        'Beneficial ownership',
        'Cash reporting',
        'CDD',
        'EDD',
        'Independent testing',
        'Periodic review',
        'Policies and procedures',
        'PEP screening',
        'Risk assessment',
        'Sanctions',
        'Suspicious activity reporting',
        'Training and education',
        'Transaction monitoring'
    ];

    const handleInputChange = (key, value) => {
        dispatch(updateStepData({
            step: 'amlctf',
            data: { [key]: value }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>AML, CTF AND SANCTIONS PROGRAM</SectionTitle>
                <Description>
                    Does the entity have a program that sets minimum AML, CTF and Sanctions standards
                    regarding the following components:
                </Description>
                <Description>Brief description goes here...</Description>

                <ProgramTable>
                    <TableHeader>
                        <HeaderItem>Products and services</HeaderItem>
                        <HeaderItem align="center">Yes</HeaderItem>
                        <HeaderItem align="center">No</HeaderItem>
                    </TableHeader>

                    {programComponents.map((component) => (
                        <TableRow key={component}>
                            <div>{component}</div>
                            <RadioGroup align="center">
                                <Label>
                                    <input
                                        type="radio"
                                        name={`program_${component}`}
                                        value="yes"
                                        checked={formData[component] === 'yes'}
                                        onChange={() => handleInputChange(component, 'yes')}
                                    />
                                </Label>
                            </RadioGroup>
                            <RadioGroup align="center">
                                <Label>
                                    <input
                                        type="radio"
                                        name={`program_${component}`}
                                        value="no"
                                        checked={formData[component] === 'no'}
                                        onChange={() => handleInputChange(component, 'no')}
                                    />
                                </Label>
                            </RadioGroup>
                        </TableRow>
                    ))}
                </ProgramTable>

                <FormSection>
                    <Description>
                        Is the Entity's AML, CTF & Sanctions policy approved at least annually by the Board or equivalent Senior Management Committee?
                    </Description>
                    <RadioGroup>
                        <Label>
                            <input
                                type="radio"
                                name="policyApproved"
                                value="no"
                                checked={formData.policyApproved === 'no'}
                                onChange={() => handleInputChange('policyApproved', 'no')}
                            />
                            No
                        </Label>
                        <Label>
                            <input
                                type="radio"
                                name="policyApproved"
                                value="yes"
                                checked={formData.policyApproved === 'yes'}
                                onChange={() => handleInputChange('policyApproved', 'yes')}
                            />
                            Yes
                        </Label>
                    </RadioGroup>
                </FormSection>

                <FormSection>
                    <Description>
                        Does the Entity use third parties to carry out any components of its AML, CTF & Sanctions program?
                    </Description>
                    <RadioGroup>
                        <Label>
                            <input
                                type="radio"
                                name="usesThirdParties"
                                value="no"
                                checked={formData.usesThirdParties === 'no'}
                                onChange={() => handleInputChange('usesThirdParties', 'no')}
                            />
                            No
                        </Label>
                        <Label>
                            <input
                                type="radio"
                                name="usesThirdParties"
                                value="yes"
                                checked={formData.usesThirdParties === 'yes'}
                                onChange={() => handleInputChange('usesThirdParties', 'yes')}
                            />
                            Yes
                        </Label>
                    </RadioGroup>

                    {formData.usesThirdParties === 'yes' && (
                        <ConditionalSection>
                            <Description>Please provide further details</Description>
                            <TextArea
                                value={formData.thirdPartyDetails || ''}
                                onChange={(e) => handleInputChange('thirdPartyDetails', e.target.value)}
                                placeholder="Placeholder"
                            />
                        </ConditionalSection>
                    )}
                </FormSection>

                <FormSection>
                    <Description>
                        Does the entity have a whistleblower policy?
                    </Description>
                    <RadioGroup>
                        <Label>
                            <input
                                type="radio"
                                name="hasWhistleblowerPolicy"
                                value="no"
                                checked={formData.hasWhistleblowerPolicy === 'no'}
                                onChange={() => handleInputChange('hasWhistleblowerPolicy', 'no')}
                            />
                            No
                        </Label>
                        <Label>
                            <input
                                type="radio"
                                name="hasWhistleblowerPolicy"
                                value="yes"
                                checked={formData.hasWhistleblowerPolicy === 'yes'}
                                onChange={() => handleInputChange('hasWhistleblowerPolicy', 'yes')}
                            />
                            Yes
                        </Label>
                    </RadioGroup>
                </FormSection>
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default AMLCTFSanctionPrograms;