import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentStep: 'entity',
    completedSteps: [],
    steps: {
        entity: {
            completed: false,
            data: {
                financialInstitutionName: '',
                location: '',
                fullLegalName: '',
                foreignBranches: '',
                legalAddress: '',
                businessAddress: '',
                dateOfIncorporation: '',
                ownershipType: '',
                isPubliclyTraded: 'no',
                exchangeInfo: '',
                isMemberOwned: 'no',
                isGovernmentOwned: 'no',
                isPrivatelyOwned: 'no',
                shareholderDetails: '',
                bearerSharePercentage: '',
                hasOffshoreBranches: 'no',
                branchDetails: '',
                hasVirtualBank: 'no',
                legalEntityIdentifier: ''
            }
        },
        amlctf: {
            completed: false,
            data: {
                hasAMLPolicy: 'no',
                policyLastUpdated: '',
                approvalLevel: '',
                independentTesting: 'no',
                lastTestingDate: '',
                employeeTraining: 'no',
                lastTrainingDate: '',
                riskAssessment: 'no',
                lastAssessmentDate: '',
                transactionMonitoring: 'no',
                monitoringSystem: '',
                regulatoryReporting: 'no',
                regulatoryBody: ''
            }
        },
        antibribery: {
            completed: false,
            data: {
                hasAntiCorruptionPolicy: 'no',
                policyLastUpdated: '',
                trainingProvided: 'no',
                lastTrainingDate: '',
                riskAssessment: 'no',
                lastAssessmentDate: '',
                incidentReporting: 'no',
                reportingProcedure: '',
                independentReview: 'no',
                lastReviewDate: ''
            }
        },
        amlpolicies: {
            completed: false,
            data: {
                dueDiligencePolicies: '',
                riskBasedApproach: 'no',
                customerRiskFactors: '',
                ongoingMonitoring: 'no',
                monitoringFrequency: '',
                recordKeeping: 'no',
                retentionPeriod: ''
            }
        },
        kyc: {
            completed: false,
            data: {
                customerIdentification: 'no',
                identificationMethods: '',
                beneficialOwnership: 'no',
                ownershipThreshold: '',
                enhancedDueDiligence: 'no',
                highRiskCriteria: '',
                pepScreening: 'no',
                screeningFrequency: ''
            }
        },
        monitoring: {
            completed: false,
            data: {
                transactionType: '',
                monitoringSystem: '',
                alertGeneration: '',
                reviewProcess: '',
                escalationProcedures: '',
                reportingThresholds: '',
                recordRetention: ''
            }
        },
        payment: {
            completed: false,
            data: {
                paymentTypes: [],
                crossBorderControls: '',
                sanctionsScreening: 'no',
                screeningStage: '',
                dataRetention: '',
                messageStandards: ''
            }
        },
        sanctions: {
            completed: false,
            data: {
                sanctionsPolicy: 'no',
                policyScope: '',
                screeningMethods: '',
                listsCovered: [],
                updateFrequency: '',
                matchHandling: '',
                escalationProcess: ''
            }
        },
        training: {
            completed: false,
            data: {
                trainingProgram: 'no',
                programScope: '',
                frequency: '',
                content: '',
                recordKeeping: '',
                effectiveness: '',
                updatesProcess: ''
            }
        },
        audit: {
            completed: false,
            data: {
                internalAudit: 'no',
                auditFrequency: '',
                lastAuditDate: '',
                findings: '',
                remediation: '',
                regulatoryExams: 'no',
                examFindings: ''
            }
        }
    },
    isFormDirty: false,
    lastSaved: null
};

const wolfsbergSlice = createSlice({
    name: 'wolfsberg',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        completeStep: (state, action) => {
            const stepId = action.payload;
            if (!state.completedSteps.includes(stepId)) {
                state.completedSteps.push(stepId);
                state.steps[stepId].completed = true;
            }
        },
        updateStepData: (state, action) => {
            const { step, data } = action.payload;
            state.steps[step].data = {
                ...state.steps[step].data,
                ...data
            };
            state.isFormDirty = true;
        },
        setFormDirty: (state, action) => {
            state.isFormDirty = action.payload;
        },
        saveProgress: (state) => {
            state.lastSaved = new Date().toISOString();
            state.isFormDirty = false;
        },
        resetForm: () => initialState
    }
});

// Export actions
export const {
    setCurrentStep,
    completeStep,
    updateStepData,
    setFormDirty,
    saveProgress,
    resetForm
} = wolfsbergSlice.actions;

// Export selectors
export const selectCurrentStep = (state) => state.wolfsberg.currentStep;
export const selectCompletedSteps = (state) => state.wolfsberg.completedSteps;
export const selectStepData = (stepId) => (state) => state.wolfsberg.steps[stepId]?.data;
export const selectIsStepCompleted = (stepId) => (state) => state.wolfsberg.steps[stepId].completed;
export const selectIsFormDirty = (state) => state.wolfsberg.isFormDirty;
export const selectLastSaved = (state) => state.wolfsberg.lastSaved;
export const selectAllStepsData = (state) => {
    const { steps } = state.wolfsberg;
    return Object.keys(steps).reduce((acc, stepId) => {
        acc[stepId] = steps[stepId].data;
        return acc;
    }, {});
};

export default wolfsbergSlice.reducer;