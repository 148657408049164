import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepData, selectStepData } from '../../store/complianceSlice';
import ActionButtons from '../../components/common/ActionButtons';

const FormSection = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #333;
  text-transform: uppercase;
`;

const SubSection = styled.div`
  margin-bottom: 32px;
`;

const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  display: block;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const Select = styled.select`
  width: 60%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #f26e22;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;

  input[type="radio"] {
    margin-top: 2px;
  }
`;

const NestedSection = styled.div`
  margin-left: 24px;
  margin-top: 16px;
  padding-left: 16px;
  border-left: 2px solid #eee;
`;

const DeclarationBusinessForm = ({ onSaveContinue, onSaveLater, onCancel }) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectStepData('declaration'));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateStepData({
            step: 'declaration',
            data: { [name]: value }
        }));
    };

    return (
        <>
            <FormSection>
                <SectionTitle>DECLARATION OF BUSINESS</SectionTitle>

                <SubSection>
                    <SubTitle>Business overview</SubTitle>
                    <Description>
                        With the aid of flow diagrams, including all parties involved, provide the customer's comprehensive business
                        description and flow of funds (nature and extent of the business, main products, and services offered,
                        revenue streams, and suppliers)
                    </Description>
                    <FormGroup>
                        <TextArea
                            name="businessOverview"
                            value={formData?.businessOverview || ''}
                            onChange={handleInputChange}
                            placeholder="Enter overview"
                        />
                    </FormGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Relationship purpose</SubTitle>
                    <FormGroup>
                        <Label>Select the type(s) of payments that the customer would like to use Mastercard Cross-Border Services for</Label>
                        <Select
                            name="paymentType"
                            value={formData?.paymentType || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select type</option>
                            <option value="P2P">P2P</option>
                            {/* Add other options */}
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <Label>
                            What activities does the customer want to facilitate based on the type(s) of payments? (e.g. Disbursement
                            of payroll, incoming of B2 payments, intra-company transfers, markup of customer funds, payout of
                            customer funds etc.)
                        </Label>
                        <TextArea
                            name="activities"
                            value={formData?.activities || ''}
                            onChange={handleInputChange}
                            placeholder="Enter activities"
                        />
                    </FormGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Describe frequency of ongoing sanctions screening</SubTitle>
                    <FormGroup>
                        <Select
                            name="screeningFrequency"
                            value={formData?.screeningFrequency || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select type</option>
                            <option value="">Others</option>
                            {/* Add frequency options */}
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <Label>Explain 'Others' selection</Label>
                        <TextArea
                            name="screeningExplanation"
                            value={formData?.screeningExplanation || ''}
                            onChange={handleInputChange}
                            placeholder="Enter explanation"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Scope of Sanctions screening</Label>
                        <Select
                            name="screeningScope"
                            value={formData?.screeningScope || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select type</option>
                            <option value="">Sender</option>
                            {/* Add scope options */}
                        </Select>
                    </FormGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Source of funds</SubTitle>
                    <Description>
                        Provide a description of the revenue streams that will be used to fund the relationship - main revenue
                        streams, transactional flow, customer funds, third party(ies))
                    </Description>
                    <FormGroup>
                        <TextArea
                            name="fundsSource"
                            value={formData?.fundsSource || ''}
                            onChange={handleInputChange}
                            placeholder="Enter description"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Customer payment origination methods</Label>
                        <Select
                            name="paymentMethod"
                            value={formData?.paymentMethod || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select type</option>
                            <option value="">Bank account</option>
                            {/* Add payment method options */}
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <Label>Bank account type</Label>
                        <Select
                            name="accountType"
                            value={formData?.accountType || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select type</option>
                            <option value="">Phone banking</option>
                            {/* Add account type options */}
                        </Select>
                    </FormGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Reseller and Transaction originator information</SubTitle>
                    <RadioGroup>
                        <Label>Will the customer be acting as a Reseller and submitting nested payments on behalf of any Transaction Originator?</Label>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isReseller"
                                value="no"
                                checked={formData?.isReseller === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="isReseller"
                                value="yes"
                                checked={formData?.isReseller === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Agency Model</SubTitle>
                    <RadioGroup>
                        <Label>Will the customer operate agents to use Mastercard Cross-Border Services?</Label>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="usesAgents"
                                value="no"
                                checked={formData?.usesAgents === 'no'}
                                onChange={handleInputChange}
                            />
                            No
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="usesAgents"
                                value="yes"
                                checked={formData?.usesAgents === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes
                        </RadioLabel>
                    </RadioGroup>
                </SubSection>

                <SubSection>
                    <SubTitle>Offshore clients</SubTitle>
                    <RadioGroup>
                        <Label>Will the customer offer MTS services to clients residing outside of the jurisdiction where the institution is incorporated and licensed?</Label>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOffshoreClients"
                                value="no"
                                checked={formData?.hasOffshoreClients === 'no'}
                                onChange={handleInputChange}
                            />
                            No, customer won't be offering MTS services
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                name="hasOffshoreClients"
                                value="yes"
                                checked={formData?.hasOffshoreClients === 'yes'}
                                onChange={handleInputChange}
                            />
                            Yes, customer will be offering MTS services
                        </RadioLabel>
                    </RadioGroup>

                    {formData?.hasOffshoreClients === 'yes' && (
                        <NestedSection>
                            <FormGroup>
                                <Label>1. Provide detailed description of the organization's financial license authorization and capacity to operate under this model</Label>
                                <TextArea
                                    name="licenseDescription"
                                    value={formData?.licenseDescription || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter description"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>2. Confirmation of compliance with new and existing legal and regulatory requirements from the jurisdiction(s) where clients are based (if required)</Label>
                                <TextArea
                                    name="complianceConfirmation"
                                    value={formData?.complianceConfirmation || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter confirmation"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>3. List of jurisdictions where clients reside (based on list of jurisdictions, list of clients could be required)</Label>
                                <TextArea
                                    name="jurisdictionList"
                                    value={formData?.jurisdictionList || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter jurisdictions"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>4. AML policies and procedures to onboard and monitor clients based offshore (if a separate document from the general AML policy)</Label>
                                <TextArea
                                    name="amlPolicies"
                                    value={formData?.amlPolicies || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter AML policies and procedures"
                                />
                            </FormGroup>
                        </NestedSection>
                    )}
                </SubSection>
            </FormSection>

            <ActionButtons
                onSaveContinue={onSaveContinue}
                onSaveLater={onSaveLater}
                onCancel={onCancel}
            />
        </>
    );
};

export default DeclarationBusinessForm;