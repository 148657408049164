import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from "react-hot-toast";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { deleteDestination, fetchDestinations } from "../../services/api";
import Spinner from '../common/Spinner';
import { setGridData, setLoading, setError } from '../../store/destinationSlice';

const GridContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const GridButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const GridButton = styled.button`
  padding: 8px 16px;
  background: none;
  border: none;
  color: #f26e22;
  cursor: pointer;
  margin: 0 5px;
`;

const CountryCard = styled.div`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
`;

const CountryHeader = styled.div`
  background-color: #f9f9f9;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const CountryName = styled.div`
  strong {
    margin-right: 10px;
  }
  span {
    color: #888;
  }
`;

const CountryContent = styled.div`
  padding: 10px 20px;
  background-color: #fff;
`;

const ActionButtons = styled.div`
  margin-bottom: 10px;
  button {
    margin-right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    &.edit {
      color: #f26e22;
    }
    &.remove {
      color: #dc3545;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    font-weight: bold;
  }
`;

const NoServices = styled.p`
  color: #666;
  font-style: italic;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  color: #666;
  font-style: italic;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
`;

const DestinationGrid = ({ onEdit, showConfirmation }) => {
    const dispatch = useDispatch();
    const { gridData, isLoading } = useSelector(state => state.destinations);
    const [expandedRows, setExpandedRows] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc");

    const loadDestinations = async () => {
        try {
            dispatch(setLoading(true));
            const data = await fetchDestinations();

            dispatch(setGridData(data));
        } catch (error) {
            console.error("Error loading destinations:", error);
            dispatch(setError(error.message));
            toast.error("Failed to load destinations");
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (!gridData.length) {
            loadDestinations();
        }
    }, [dispatch]);

    const handleRemoveDestination = (countryToRemove) => {
        showConfirmation(
            `Are you sure you want to remove ${countryToRemove} from your destinations?`,
            async () => {
                try {
                    await deleteDestination(countryToRemove);
                    await loadDestinations(); // Refresh grid after deletion
                    setExpandedRows([]);
                    toast.success("Destination removed successfully");
                } catch (error) {
                    toast.error("Failed to remove destination");
                }
            }
        );
    };

    const handleEditDestination = (country) => {
        const countryData = gridData.find(item => item.country === country);
        if (countryData && onEdit) {
            const editData = {
                country: countryData.country,
                services: countryData.services.map(service => ({
                    service: service.service,
                    payoutCurrency: service.payoutCurrency,
                    payoutType: service.payoutType
                }))
            };
            onEdit(editData);
        }
    };

    const toggleRow = (index) => {
        setExpandedRows(prevState =>
            prevState.includes(index)
                ? prevState.filter(i => i !== index)
                : [...prevState, index]
        );
    };

    const expandAll = () => {
        setExpandedRows(gridData.map((_, index) => index));
    };

    const collapseAll = () => {
        setExpandedRows([]);
    };

    const sortCountries = () => {
        if (gridData.length == 1) {
            toast.error("Only one destination available. Cannot sort.");
            return;
        }
        const sorted = [...gridData].sort((a, b) => {
            return sortOrder === "asc"
                ? a.country.toUpperCase().localeCompare(b.country.toUpperCase())
                : b.country.toUpperCase().localeCompare(a.country.toUpperCase());
        });

        dispatch(setGridData(sorted));
        setSortOrder(sortOrder === "desc" ? "asc" : "desc");
        setExpandedRows([]);
    };

    if (isLoading && !gridData.length) {
        return (
            <LoadingContainer>
                <Spinner />
            </LoadingContainer>
        );
    }

    if (!gridData || !gridData.length) {
        return (
            <EmptyState>
                No destinations added yet. Click the button above to add your first destination.
            </EmptyState>
        );
    }

    return (
        <GridContainer>
            <GridButtons>
                <GridButton onClick={sortCountries}>
                    Sort by {sortOrder === "asc" ? "Z-A" : "A-Z"}
                </GridButton>
                <div>
                    <GridButton onClick={expandAll}>Expand All</GridButton>
                    <GridButton onClick={collapseAll}>Collapse All</GridButton>
                </div>
            </GridButtons>

            {gridData.map((country, index) => (
                <CountryCard key={index}>
                    <CountryHeader onClick={() => toggleRow(index)}>
                        <CountryName>
                            <strong>{country.country}</strong>
                            <span>{country.originatingCountry}</span>
                        </CountryName>
                        <div>
                            {expandedRows.includes(index) ?
                                <MdOutlineKeyboardArrowUp /> :
                                <MdOutlineKeyboardArrowDown />
                            }
                        </div>
                    </CountryHeader>

                    {expandedRows.includes(index) && (
                        <CountryContent>
                            <ActionButtons>
                                <button
                                    className="edit"
                                    onClick={() => handleEditDestination(country.country)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="remove"
                                    onClick={() => handleRemoveDestination(country.country)}
                                >
                                    Remove
                                </button>
                            </ActionButtons>

                            {country.services.length > 0 ? (
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Service</th>
                                            <th>Payout Currency</th>
                                            <th>Payout Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {country.services.map((service, idx) => (
                                            <tr key={idx}>
                                                <td>{service.service}</td>
                                                <td>{Array.isArray(service.payoutCurrency) ?
                                                    service.payoutCurrency.join(', ') :
                                                    service.payoutCurrency}
                                                </td>
                                                <td>{Array.isArray(service.payoutType) ?
                                                    service.payoutType.join(', ') :
                                                    service.payoutType}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <NoServices>No services available.</NoServices>
                            )}
                        </CountryContent>
                    )}
                </CountryCard>
            ))}
        </GridContainer>
    );
};

export default DestinationGrid;