import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { Toaster, toast } from "react-hot-toast";
import { useHistory } from 'react-router-dom';
import ActionButtons from '../../components/common/ActionButtons';
import { uploadDocument } from '../../services/api';

const Container = styled.div`
height: 100%;  
max-width: 800px;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
`;

const BackLink = styled(Link)`
  color: #f26e22;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 32px;
`;

const Section = styled.div`
  margin-bottom: 48px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #f26e22;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ddd;
`;

const UploadBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FileIcon = styled.span`
  color: #666;
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileName = styled.span`
  font-weight: 500;
  color: #333;
`;

const FileSize = styled.span`
  font-size: 12px;
  color: #666;
`;

const RemoveButton = styled.button`
  color: #f26e22;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin-top: 8px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.value}%;
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
`;

const UploadDocument = () => {
  const history = useHistory();
  const [uploadedFile, setUploadedFile] = useState(() => {
    const savedFile = localStorage.getItem('uploadedFile');
    return savedFile ? JSON.parse(savedFile) : null;
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileData, setFileData] = useState(() => {
    const savedData = localStorage.getItem('uploadedFileData');
    return savedData ? JSON.parse(savedData) : null;
  });
  const [originalFile, setOriginalFile] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUploadProgress(0);
      setFileData(null);
      setOriginalFile(null);
      setUploadedData([]);
    };
  }, []);

  const simulateUpload = () => {
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress(prev => {
        const increment = Math.floor(Math.random() * 15) + 5;
        const newProgress = Math.min(prev + increment, 100);
        if (newProgress >= 95) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 200);

    return () => clearInterval(interval);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const fileInfo = {
        name: file.name,
        size: file.size,
        type: file.type
      };
      setUploadedFile(fileInfo);
      setOriginalFile(file);
      localStorage.setItem('uploadedFile', JSON.stringify(fileInfo));


      simulateUpload();

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Validate data
        if (!jsonData || jsonData.length === 0) {
          throw new Error('No data found in the Excel file');
        }
        setFileData(jsonData);
        setUploadedData(jsonData);
        localStorage.setItem('uploadedFileData', JSON.stringify(jsonData));
        setTimeout(() => {
          setUploadProgress(100);
          toast.success('Document uploaded successfully');
        }, 300);
      };
      reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please upload a valid Excel (.xlsx) document');
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    setUploadProgress(0);
    setFileData(null);
    setOriginalFile(null);
    setUploadedData([]);
    localStorage.removeItem('uploadedFile');
    localStorage.removeItem('uploadedFileData');
    toast.success("Document removed successfully");
  };

  const handleSaveContinue = async () => {
    try {
      setIsLoading(true);
      if (!originalFile) {
        toast.error('No file selected for upload');
        return;
      }

      const response = await uploadDocument(originalFile);

      if (response?.data?.list) {
        setUploadedData(response.data.list);
        toast.success('Document saved successfully');
        setTimeout(() => {
          setIsLoading(false);
          history.push('/next-page');
        }, 1500);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      setIsLoading(false);
      let errorMessage = 'Failed to save document';

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Invalid file format or content';
            break;
          case 413:
            errorMessage = 'File size too large';
            break;
          case 401:
            errorMessage = 'Unauthorized access';
            break;
          default:
            errorMessage = error.response.data?.message || 'Server error occurred';
        }
      }

      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSaveLater = async () => {
    try {
      setIsLoading(true);

      if (!originalFile) {
        toast.error('Please select a file first');
        return;
      }

      if (!fileData || fileData.length === 0) {
        toast.error('No data found in the file');
        return;
      }

      localStorage.setItem('savedFileData', JSON.stringify({
        file: {
          name: originalFile.name,
          size: originalFile.size,
          type: originalFile.type,
          lastModified: originalFile.lastModified
        },
        data: fileData,
        savedAt: new Date().toISOString()
      }));

      toast.success('Document saved successfully for later use');
      setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
        history.push('/compliance');
      }, 1000); // 1.5 second delay

    } catch (error) {
      toast.error('Failed to save document: ' + (error.message || 'Unknown error'));
    } finally {
      setIsLoading(false);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 KB';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };
  return (
    <Container>
      <Toaster position="top-right"
        toastOptions={{
          success: { style: { background: 'green', color: '#fff' } },
          error: { style: { background: 'red', color: '#fff' } },
        }} />
      <BackLink to="/">
        ← Cross Border Services setup
      </BackLink>

      <Title>Payment destination required documentation</Title>

      <Description>
        We'll need to collect a required document from you. Make sure to submit documentation in English.
      </Description>

      <Section>
        <SectionTitle>Final Payment destinations</SectionTitle>
        <Description>
          We'll need a copy of your business case document which includes your payment destinations.
          This document will inform us of your exact payment preferences.
        </Description>

        {!uploadedFile ? (
          <FileUploadLabel>
            + Add final business case document
            <HiddenInput
              type="file"
              onChange={handleFileUpload}
              accept=".xlsx"
            />
          </FileUploadLabel>
        ) : (
          <UploadBox>
            <FileInfo>
              <FileIcon>📎</FileIcon>
              <FileDetails>
                <FileName>{uploadedFile.name}</FileName>
                <FileSize>{formatFileSize(uploadedFile.size)}</FileSize>
                {uploadProgress < 100 && (
                  <ProgressBar>
                    <Progress value={uploadProgress} />
                  </ProgressBar>
                )}
              </FileDetails>
            </FileInfo>
            <RemoveButton onClick={handleRemoveFile}>
              Remove
            </RemoveButton>
          </UploadBox>
        )}
      </Section>

      <Divider />

      <ActionButtons
        onSaveContinue={handleSaveContinue}
        onSaveLater={handleSaveLater}
        onCancel={handleRemoveFile}
        disabled={isLoading}
      />
    </Container>
  );
};

export default UploadDocument;